import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "@reach/router";

import axios from 'axios';
import edusim from '../../config/edusim';

import {
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Container,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  Grid,
  Chip,
  Box,
  IconButton,
  Select,
  MenuItem
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import UserIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

import applicationAvatarPlaceholder from '../../imgs/maleAvatarPlaceholder.png';

//import data from '../config/data';
import { Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper
 } from '@material-ui/core';
import { ApiFunctions } from '../../services/Api';

const gaussian = require('gaussian');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    width: 300,
    height:450,
    paddingBottom: theme.spacing(2)
  },
  media: {
    height: 300
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));


export default function ApplicationsList(props) {
  
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const authorizedMessage = "You are not authorized to use this service. Only system admins and admissions employees have access to admission management.";
  const [authorized, setAuthorized] = React.useState(true);
  const [totalApplicationsCount, setTotalApplicationsCount] = React.useState(0);
  const [applications, setApplications] = React.useState([]);
  const [schools, setSchools] = React.useState([]);
  const [currentSearchPhrase, setCurrentSearchPhrase] = React.useState('');
  const [lastSearchPhrase, setLastSearchPhrase] = React.useState('');
  const [lastSchoolId, setLastSchoolId] = React.useState(null);
  const [toggleScrollToBottom, setToggleScrollToBottom] = React.useState(0);

  const searchLimit = 8;

  const colorsAvailable = 
  ["#d0fffe",
  "#fffddb",
  "#e4ffde",
  "#ffd3fd",
  "#ffe7d3"];

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
 
  const classes = useStyles();

  React.useEffect(() => {

    isMounted.current = true;

    const refreshApplications = () => {
        setLoading(true);
        ApiFunctions.listApplications(propsUser).then((res)=>{
          console.log(res);
          setAuthorized(true);
          setApplications(res);
          ApiFunctions.countAllApplications(propsUser).then(res=>{
            setTotalApplicationsCount(res.count);
            ApiFunctions.listSchools(propsUser).then((res)=>{
              setSchools(res);
            });
          });
        }).catch(e=>{
          handleErrors(e);
        }).finally(()=>{
          isMounted.current&&setLoading(false);
        });
    }

    const handleErrors = (e) =>{
      console.log(e);
      if(e.response&&e.response.status === 401){
        setAuthorized(false);
      }else if(e.response&&e.response.status === 403){
        setTimeout(()=>propsUpdateUser({}),2000);
      }else if(e.response&&e.response.status !== 200){
        setError("There seems to be a problem with the service at the momemt. Please try again later.");
      }else{
        setError("Something went wrong while reaching the API service. Are you connected to the internet?");
      }
    }

    refreshApplications();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [propsUpdateUser, propsUser]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error !== '') {
          setError('');
        }
      }
    }, 3000);
  }, [error]);

  const applicationsEndRef = React.useRef(null);

  const scrollToBottom = () =>{ 
    console.log("scrolling to bottom");
    applicationsEndRef.current&&applicationsEndRef.current.scrollIntoView({behavior: 'smooth'});
  }; 

  React.useEffect(() => {
    toggleScrollToBottom&&scrollToBottom();
  }, [toggleScrollToBottom]);

  const searchEnterKey = (e) =>{
    if (/* ev.ctrlKey && */ e.key === 'Enter') {
      e.preventDefault();
      searchApplications(lastSchoolId);
    }
  }
  
  const findMean = (arr = []) => {
    let itemSum = 0;
    arr.forEach((item)=>{
      itemSum += item;
    });
    return (itemSum/(arr.length));
  } 

  const findVariance = (arr = []) => {
    if(!arr.length){
        return 0;
    };
    const sum = arr.reduce((acc, val) => acc + val);
    const { length: num } = arr;
    const median = sum / num;
    let variance = 0;
    arr.forEach(num => {
        variance += ((num - median) * (num - median));
    });
    variance /= num;
    return variance;
  };
  //console.log(findVariance(arr))
  const arr = [68, 68, 75, 75, 78, 79, 81, 81, 85, 90, 92, 95, 96, 97, 97];
  const dMean = findMean(arr);
  const dStdv = Math.sqrt(findVariance(arr));
  const threeSigma = dMean+(3*dStdv);
  const mthreeSigma  = dMean-(3*dStdv);

  const loadMore = ()=>{
    //var distribution = gaussian(findMean(arr),findVariance(arr));
    // Take a random sample using inverse transform sampling method.
    //var sample = distribution.ppf(Math.random());
    /* console.log(dMean,dStdv);
    let stdNumbers = distribution.random(40);
    stdNumbers.forEach((n)=>{
      console.log(n);
    })
    stdNumbers.forEach((n)=>{
      console.log(distribution.pdf(n));
    }) */

    let beginNumber = Math.floor(mthreeSigma-1.5);
    let endNumber = Math.ceil(threeSigma+1.5)
    console.log(dMean,dStdv);
    let normalized = [];
    for(let s=beginNumber; s<=endNumber;s++){
      console.log(s);
    }

    var distribution = gaussian(dMean,findVariance(arr));
    for(let s=beginNumber; s<=endNumber;s++){
      console.log(distribution.pdf(s));
    }


    let lastApplicationID = (applications.length>0)?applications[applications.length-1]:null;
    ApiFunctions.listMoreApplications(propsUser, lastSearchPhrase, lastApplicationID, lastSchoolId).then(res=>{
      let tempApplications = [...applications];
      tempApplications = [...tempApplications, ...res.applications];
      setApplications(tempApplications);
      setToggleScrollToBottom(toggleScrollToBottom+1);
    })
  }

  const searchApplications = (_schoolid)=>{
    setLastSearchPhrase(currentSearchPhrase.trim());
    ApiFunctions.listMoreApplications(propsUser, currentSearchPhrase.trim(), null, _schoolid).then(res=>{
      setApplications(res.applications);
    });
  }

  const handleSearchPhraseChange= (e) =>{
    setCurrentSearchPhrase(e.target.value);
  }

  const handleSelectSchool = (e)=>{
    let _schoolid = e.target.value==='All'?null:e.target.value;
    setLastSchoolId(_schoolid);
    searchApplications(_schoolid);
  }

  /* function getUserRoles(user){
    console.log(user);
    let roles = [];
    user._instructorid&&roles.push("Instructor");
    user._guardianid&&roles.push("Guardian");
    user.roles&&user.roles.length>0&&roles.push("Administrator");
    let rolesText = roles.join(", ");
    return rolesText;
  } */

  function getApplicationAvatarSrc(_applicationid){
    let randNum = Math.floor(Math.random()*1000) + 3;
    return ApiFunctions.getApiUri()+'/api/avatar/applications/'+_applicationid+'/?token='+propsUser.token+'&rand='+randNum
  }

  return (
    !props.user.active||!props.user.mobile?
      <Grid item xs={12}>
        <Alert style={{marginBottom: 10}} severity='error'>
            Both your email and mobile must be verified to use this service
        </Alert>
      </Grid>
    :
    !authorized?
      <Grid item xs={12}>
        <Alert style={{marginBottom: 10}} severity='error'>
            {authorizedMessage}
        </Alert>
      </Grid>
    :

    <Container maxWidth="xl" className={classes.container}>
      {(error !== '') &&
        <Grid item xs={12}>
          <Alert style={{ marginBottom: 10 }} severity='error'>
            {error}
          </Alert>
        </Grid>
      }

      <Box display="flex" flexDirection="row" alignItems="center">
        <Box>
          <h2 style={{ padding: 0, margin: 0, flex: 1 }}>Admission Applications</h2>
        </Box>
      </Box>

      <Grid container style={{marginTop: 10}} spacing={3}>
        <Grid item xs={6}>
          <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="search-field">
            Search Applications
          </InputLabel>
          <Input
            id="search-field"
            onChange={(e) => handleSearchPhraseChange(e)}
            onKeyPress={(e) => { searchEnterKey(e);}}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth>
        <InputLabel id="schoolLabel">School</InputLabel>
        <Select 
                defaultValue="All"
                labelId="schoolLabel"
                label="School"
                onChange={(e) => handleSelectSchool(e)}>
                    <MenuItem value="All">
                        All
                    </MenuItem>
                    {schools.map((school, key)=>(
                        <MenuItem key={key} value={school._id}>
                            {school.name}
                        </MenuItem>
                    ))}
                </Select>  
                </FormControl>  
        </Grid>
        <Grid item xs={6}>
          <h2>{applications.length} of {totalApplicationsCount} Applications Shown.</h2>
        </Grid>
      </Grid>

      
      
      {/* <TextField
        label="Search" variant="outlined" fullWidth
        onChange={(e) => handleSearchPhraseChange(e)}
        onKeyPress={(e) => { searchEnterKey(e);}}> 
      </TextField> */}
      
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={4}>
            {(loading ? Array.from(new Array(3)) : applications).map((obj, key) => (
              <Grid key={key} item>
                {obj ? (
                  <Link to={'/applications/'+ obj._id} style={{ textDecoration: 'none' }}>
                    <Card className={classes.cardRoot}>
                      <CardHeader style={{backgroundColor: obj.bgcolor?obj.bgcolor:"#ffffff"}}
                        avatar={
                          <Avatar aria-label="Subject" className={classes.avatar}>
                            <ListAltSharpIcon />
                          </Avatar>
                        }
                        title={obj.student?.name}
                        subheader={<div><div>{obj.guardians[0]?._id.email}</div><div>{obj._schoolid?.name}</div></div>}
                      />
                      <CardMedia
                        component="img"
                        className={classes.media}
                        src={getApplicationAvatarSrc(obj._id)}
                        onError={(e) => { e.target.onerror = null; e.target.src = applicationAvatarPlaceholder }}
                        title={obj.student?.name}
                      />
                      <CardContent>
                      <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-around">
                        <Chip
                        variant='outlined'
                        icon={<ListAltSharpIcon style={{color: edusim.admission_status_colors[obj.status]}} />} 
                        label={obj.status} 
                        style={{padding: '2px'}} />
                        {/* <Chip 
                        variant='outlined'
                        icon={<PhoneIcon style={{color: edusim.profile_status_colors[obj.mobile?'Verified':'Unverified']}} />} 
                        label={obj.mobile?'Mobile Active':'Mobile Inactive'} 
                        style={{padding: '2px'}} /> */}
                      </Box>
                      </CardContent>
                      {/* <CardActions disableSpacing>
                        
                          <Grid container spacing={2} alignItems="center" justify="center">
                          <Grid item>{obj.registeredon}</Grid>
                          {obj.mobile?
                            <Grid item>Verified Mobile: {obj.mobile}</Grid>
                          :
                            <Grid item>Mobile Not Verified</Grid>
                          }
                          
                          </Grid>
                        
                        
                      </CardActions> */}
                    </Card>
                  </Link>
                ) :
                  <Skeleton variant="rect" width={180} height={220} />
                }
              </Grid>
            ))}
           
          </Grid>
        </Grid>
        {applications.length>0&&applications.length<totalApplicationsCount&&
          <Grid item xs={12}>
          <IconButton onClick={()=>loadMore()} title="Load More Applications" color="primary">
            <MoreIcon />
          </IconButton>
          
          </Grid>
        }
        
      </Grid>
      <div ref={applicationsEndRef} />
    </Container>

  );
}