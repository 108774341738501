import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "@reach/router";

import axios from 'axios';
import edusim from '../../config/edusim';


import Alert from '@material-ui/lab/Alert';

import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import UserIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

import userAvatarPlaceholder from '../../imgs/maleAvatarPlaceholder.png';

//import data from '../config/data';
import { 
  FormControl,
  TextField,
  Button,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Grid,
  Tooltip,
  Container,
  Box
 } from '@material-ui/core';

 import { ApiFunctions } from '../../services/Api';
 
 import {Controller, useForm, useWatch} from 'react-hook-form'
 import { yupResolver } from "@hookform/resolvers/yup";
 import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    width: 300,
    height:550,
    paddingBottom: theme.spacing(2)
  },
  media: {
    height: 300
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));

export default function SchoolsList(props) {
  
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const authorizedMessage = "You are not authorized to use this service. Only system admins have access to school management.";
  const [authorized, setAuthorized] = React.useState(true);
  const [schools, setSchools] = React.useState([]);
  const [addFormVisible, setAddFormVisible] = React.useState(false);


  const colorsAvailable = 
  ["#d0fffe",
  "#fffddb",
  "#e4ffde",
  "#ffd3fd",
  "#ffe7d3"];

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
 
  const classes = useStyles();

  React.useEffect(() => {

    isMounted.current = true;

    const refreshSchools = () => {
        setLoading(true);
        ApiFunctions.listSchools(propsUser).then((res)=>{
          console.log(res);
          setAuthorized(true);
          setSchools(res);
        }).catch(e=>{
          handleErrors(e);
        }).finally(()=>{
          isMounted.current&&setLoading(false);
        });
    }

    const handleErrors = (e) =>{
      console.log(e);
      if(e.response&&e.response.status === 401){
        setAuthorized(false);
      }else if(e.response&&e.response.status === 403){
        setTimeout(()=>propsUpdateUser({}),2000);
      }else if(e.response&&e.response.status !== 200){
        setError("There seems to be a problem with the service at the momemt. Please try again later.");
      }else{
        setError("Something went wrong while reaching the API service. Are you connected to the internet?");
      }
    }

    refreshSchools();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [propsUpdateUser, propsUser]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error !== '') {
          setError('');
        }
      }
    }, 3000);
  }, [error]);

  const handleAddSchoolClick = (e) =>{
    addFormVisible?setAddFormVisible(false):setAddFormVisible(true);
  }

  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("School name is required").min(5),
    telephone: Yup.string().notRequired().matches(phoneRegex,{ excludeEmptyString: true, message:"Must be a valid international mobile number like +97377887799"}),
    translated_name: Yup.string(),
    address: Yup.string(),
    license_number: Yup.string()
  });

  const {
    handleSubmit,
    reset,
    errors: fieldsErrors,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {name:'', translated_name:'', address:'', telephone:'', license_number:''},
    resolver: yupResolver(validationSchema),
  });


  function onSubmit(data){
    //let userToSave = {...user, ...data};
    console.log(data);
    addSchool(data);
  }
  
  function addSchool(schoolToAdd){
    setLoading(true);
    ApiFunctions.saveSchool(propsUser, schoolToAdd).then((res)=>{
      setAddFormVisible(false);
      schoolToAdd._id=res;
      let tempSchools = [...schools];
      tempSchools.push(schoolToAdd);
      setSchools(tempSchools);
    }).catch((e)=>{
      setError("Cannot add school at this time.");
    }).finally(()=>{
      setLoading(false);
    });
  }
  

  return (
    !props.user.active||!props.user.mobile?
      <Grid item xs={12}>
        <Alert style={{marginBottom: 10}} severity='error'>
            Both your email and mobile must be verified to use this service
        </Alert>
      </Grid>
    :
    !authorized?
      <Grid item xs={12}>
        <Alert style={{marginBottom: 10}} severity='error'>
            {authorizedMessage}
        </Alert>
      </Grid>
    :

    <Container maxWidth="xl" className={classes.container}>
    <Box>
    {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
    </Box>
    <Grid 
    container 
    spacing={3}
    direction="row"
    justify="flex-start"
    alignItems="center">
        <Grid item>
            <Typography component="h2" variant="h6" color="primary" >Schools List</Typography>
        </Grid>
        <Grid item>
            <IconButton
                variant="contained"
                color="primary"
                onClick={(e)=>handleAddSchoolClick(e)}>
                  <AddIcon />
            </IconButton>
        </Grid>
    </Grid>
    
    {addFormVisible&&
     <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>

     <Grid container spacing={3}>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="name"
           control={control}
           defaultValue=""
           as={
             <TextField
               autoFocus
               name="name"
               label="School Name"
               variant="outlined"
               fullWidth
               error={fieldsErrors.name ? true : false}
               helperText={
                 fieldsErrors.name ? fieldsErrors.name.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="translated_name"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="translated_name"
               label="Translated Name"
               variant="outlined"
               fullWidth
               error={fieldsErrors.translated_name ? true : false}
               helperText={
                 fieldsErrors.translated_name ? fieldsErrors.translated_name.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="address"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="address"
               label="Address"
               variant="outlined"
               fullWidth
               error={fieldsErrors.address ? true : false}
               helperText={
                 fieldsErrors.address ? fieldsErrors.address.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="telephone"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="telephone"
               label="Telephone"
               variant="outlined"
               fullWidth
               error={fieldsErrors.telephone ? true : false}
               helperText={
                 fieldsErrors.telephone ? fieldsErrors.telephone.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="license_number"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="license_number"
               label="License Number"
               variant="outlined"
               fullWidth
               error={fieldsErrors.license_number ? true : false}
               helperText={
                 fieldsErrors.license_number ? fieldsErrors.license_number.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     </Grid>

     <Grid
         container
         spacing={3}
         style={{ marginTop: 10 }}
         direction="row"
         justify="flex-end"
         alignItems="center"
       >
         <Grid item>
           <Button
             variant="contained"
             color="primary"
             onClick={handleSubmit(onSubmit)}
           >
             Add School
           </Button>
         </Grid>
     </Grid>

     </form>
    }

    <Table>
      <TableHead>
          <TableRow>
              <TableCell><b>Name</b></TableCell>
              <TableCell><b>Translated</b> </TableCell>
              <TableCell><b>Telephone Number</b></TableCell>
              <TableCell><b>Actions</b></TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
          {(loading ? Array.from(new Array(3)) : schools).map((school, key) => (
          school? <TableRow key={key}>
                  <TableCell>{school.name}</TableCell>
                  <TableCell>{school.translated_name}</TableCell>
                  <TableCell>{school.telephone}</TableCell>
                  <TableCell>
                      <Tooltip title="Manage School" aria-label="Manage School">
                      <Link to={'/schools/'+ school._id} style={{ textDecoration: 'none' }}>
                          <IconButton variant="contained" color="primary">
                              <ListAltSharpIcon />
                          </IconButton>
                      </Link>
                      </Tooltip>
                  </TableCell>
              </TableRow>
          :
          <TableRow key={key}>
            <TableCell><Skeleton variant="rect" width={180} height={30} /></TableCell>
            <TableCell><Skeleton variant="rect" width={180} height={30} /></TableCell>
            <TableCell><Skeleton variant="rect" width={180} height={30} /></TableCell>
            <TableCell><Skeleton variant="rect" width={180} height={30} /></TableCell>
          </TableRow>

          ))}
      </TableBody>
  </Table>
  </Container>
  );
}