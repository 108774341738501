import React, {Fragment, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "@reach/router";

import axios from 'axios';
import edusim from '../../config/edusim';

import {
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Button,
  Container,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  Grid,
  Chip,
  Box,
  IconButton,
  Select,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import UserIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

import maleAvatarPlaceholder from '../../imgs/maleAvatarPlaceholder.png';
import femaleAvatarPlaceholder from '../../imgs/femaleAvatarPlaceholder.png';

//import data from '../config/data';
import { Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper
 } from '@material-ui/core';
import { ApiFunctions } from '../../services/Api';
import { ContactsOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  isolatedContainer:{
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1)
  },
  control: {
    padding: theme.spacing(2),
  },
  fieldLabel:{
    fontSize: "12px"
  },
  fieldValue:{
    fontWeight: "bolder",
  },
  fieldContainer:{
    borderBottom: "1px solid #ccc",
    paddingBottom: theme.spacing(1)
  }
}));

const ApplicationField = (props) =>{
  const {label, value} = props;
  const classes = useStyles();
  return(
    <Grid container className={classes.fieldContainer} direction='column'>
      <Grid item>
        <Typography className={classes.fieldLabel}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.fieldValue}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const ApplicationViewOnlyView = ({application, studentImageSrc, user, eventFlowArray, setError, updateApplicationStatus}) =>{
    const classes = useStyles();
    const [commandsAnchor, setCommandsAnchor] = React.useState(null);

    const handleCommandsClick = (e) => {
      setCommandsAnchor(e.currentTarget);
    };
  
    const handleCommandsClose = (e, event) => {
      console.log(event.commandhuman);

      //execute command on application
      event.commandhuman&&ApiFunctions.executeApplicationCommand(user, application._id, event.commandhuman)
      .then((res)=>{
        console.log(res);
        updateApplicationStatus(res.status);
      }).catch(e=>{
        handleErrors(e);
      });

      setCommandsAnchor(null);
    };

    const handleErrors = (e) =>{
      if(e.response&&e.response.status === 401){
        setError("You are not authorized to execute commands yet.");
      }else if(e.response&&e.response.status === 403){
        setError("Your session has expired.");
      }else if(e.response&&e.response.status === 400){
        setError(e.response.data.error);
      }else if(e.response&&e.response.status !== 200){
        setError("There seems to be a problem with the service at the momemt. Please try again later.");
      }else{
        setError("Something went wrong while reaching the API service. Are you connected to the internet?");
      }
    };

    const handleAdmitStudent = (e) =>{
      //execute command on application
      ApiFunctions.admitStudent(user, application._id)
      .then((res)=>{
        console.log(res);
        //updateApplicationStatus(res.status);
      }).catch(e=>{
        handleErrors(e);
      });
    }
    return (
      application&&application.guardians?
      <Fragment>
        <Paper className={classes.paper}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
          <Typography variant="h5" component="div" color="primary">Applicant Information</Typography>
          </Grid>

          <Grid item>
            <Button onClick={handleAdmitStudent}>Admit 
              </Button>          
          </Grid>
          
          <Grid item>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleCommandsClick}>
        Select Command
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={commandsAnchor}
          keepMounted
          open={Boolean(commandsAnchor)}
          onClose={handleCommandsClose}
        >

          {eventFlowArray&&eventFlowArray.map((event, key)=>(
              <MenuItem onClick={(e)=>handleCommandsClose(e, event)} key={key} value={event.command}>
                  {event.commandhuman}
              </MenuItem>
          ))}
          
        </Menu>
          </Grid>
        </Grid>
        
  
       
        
          <Grid container spacing={2} className={classes.isolatedContainer}>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                  {/* <TextField
                      disabled={true}
                      label="Applicant's Full Name (As in ID)"
                      defaultValue={application.guardians[0]._id.name} /> */}
                      <ApplicationField label={"Applicant's Full Name"} value={application.guardians[0]._id.name} />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                  <ApplicationField
                      label="Relationship to Student"
                      value={application.guardians[0].studentrelationship} />
              </FormControl>
            </Grid>
  
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                    <ApplicationField
                        label="Mobile Number"
                        value={application.guardians[0]._id.mobile} />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                    <ApplicationField
                        label="Applicant Email Address"
                        value={application.guardians[0]._id.email} />
                </FormControl>
              </Grid>
  
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                    <ApplicationField
                        label="Gender"
                        value={application.guardians[0]._id.gender} />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                    <ApplicationField
                        label="Nationality"
                        value={application.guardians[0]._id.nationality.Name} />
                </FormControl>
              </Grid>
  
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                    <ApplicationField
                        label="Date of Birth"
                        value={application.guardians[0]._id.dateofbirth.split("T")[0]} />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                    <ApplicationField
                        label="National ID"
                        value={application.guardians[0]._id.nationalid} />
                </FormControl>
              </Grid>
  
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                    <ApplicationField
                        label="Passport ID"
                        value={application.guardians[0]._id.passportid} />
                </FormControl>
              </Grid>
             
          </Grid>
  
          <Typography variant="h5" component="div" gutterBottom color="primary">Student Information</Typography>
  
          <Grid container spacing={2} className={classes.isolatedContainer}>
              <Grid item md={4}>
                  <FormControl fullWidth>
                      <Typography>{application.student.name}'s Official Picture</Typography>
                       <img
                        style={{maxWidth: 200, borderRadius: 10, border: 0}}
                        className={classes.studentAvatar}
                        variant="rounded"
                        src={studentImageSrc}
                        onError= {(e) => { e.target.onerror=null;e.target.src = application.student.demographics.gender==='male'? maleAvatarPlaceholder:femaleAvatarPlaceholder; } }
                        />
                        {/* <Button>{applicationAttachments?applicationAttachments.length:application.attachments.length} Other Attachement{!((applicationAttachments?applicationAttachments.length:application.attachments.length)===1)&&'s'}</Button>
                        <ApplicationAttachmentsView application={application} /> */}
                  </FormControl>
              </Grid>
              <Grid item md={8}>
                  <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                              <ApplicationField
                                  label="Student's Full Name (As in ID)"
                                  value={application.student.name} />
                          </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                              <ApplicationField
                                  label="Gender"
                                  value={application.student.demographics.gender} />
                          </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                              <ApplicationField
                                  label="Date of Birth"
                                  value={application.student.demographics?.dateofbirth.split("T")[0]} />
                          </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <ApplicationField
                                label="Blood Type"
                                value={application.student.health.bloodtype} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <ApplicationField
                                label="Nationality"
                                value={application.student.demographics?.nationality?.Name} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <ApplicationField
                                label="National ID"
                                value={application.student.demographics?.nationalid} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <ApplicationField
                                label="Passport ID"
                                value={application.student.demographics.passportid} />
                        </FormControl>
                      </Grid>
                  </Grid>
                
              </Grid>
          </Grid>
            <Typography variant="h5" component="div" gutterBottom color="primary">{application.attachments.length} Attachment{!((application.attachments.length)===1)&&'s'}</Typography>
            <ApplicationAttachmentsView application={application} user={user} />
          </Paper>
      </Fragment>
      
      :
      <Fragment>

      </Fragment>
    )
}

const ApplicationAttachmentsView = (props) =>{
  const {application, user} = props;
  const classes = useStyles();
  const [applicationAttachments, setApplicationAttachments] = React.useState([]);
  const attachmentsInfoLoading = false;

  React.useEffect(()=>{
    ApiFunctions.getApplicationAttachmentsInfo(user, application._id).then((res)=>{
      setApplicationAttachments(res);
    });
  }, [application, user]);

  const handleFileDownload = (fileid) => {
    window.open(edusim.api_base_uri
      +'/api/attachments/applications/'+application._id+'/'+fileid
      +"?token="+user.token);
  }

  return (
    <Fragment>
      {/* <Typography>{application.attachments.length} Attachement{!((application.attachments.length)===1)&&'s'}</Typography> */}
      <List dense={false}>
        {!attachmentsInfoLoading && applicationAttachments.map((obj, key)=>
          (<ListItem button key={key} onClick={()=>handleFileDownload(obj._id)} /* disabled={removingFile[obj._id]?true:false} */>
            <ListItemAvatar>
              <Avatar>
                <ListAltSharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={obj.filename}
              primaryTypographyProps={{ noWrap: true }}
              secondary={obj.contentType + " ("+ Math.floor(obj.length/1024)+" KB)"}
            />
            <ListItemSecondaryAction>
              {/* {removingFile[obj._id] && <CircularProgress />} */}
              {/* {!removingFile[obj._id] &&
              <IconButton onClick={()=>handleFileRemove(obj._id)} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>} */}
            </ListItemSecondaryAction>
          </ListItem>

        ))} 
        {attachmentsInfoLoading && <CircularProgress size={50} className={classes.inButtonProgress} />} 
        </List>
    </Fragment>
  )
}

export default function ApplicationView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const applicationId = props._applicationid;
  const authorizedMessage = "You are not authorized to use this service. Only system admins and admissions employees have access to admission management.";
  const [authorized, setAuthorized] = React.useState(true);
  const [application, setApplication] = React.useState({});
  const [eventFlowArray, setEventFlowArray] = React.useState([]);

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
 
  const classes = useStyles();

  React.useEffect(() => {

    isMounted.current = true;

    const refreshApplication = () => {
        setLoading(true);
        ApiFunctions.getApplication(propsUser, applicationId).then((res)=>{
          console.log(res);
          setAuthorized(true);
          setApplication(res);
          ApiFunctions.getApplicationCommands(propsUser).then((res)=>{
            setEventFlowArray(res);
          });
        }).catch(e=>{
          handleErrors(e);
        }).finally(()=>{
          isMounted.current&&setLoading(false);
        });
    }

    const handleErrors = (e) =>{
      console.log(e);
      if(e.response&&e.response.status === 401){
        setAuthorized(false);
      }else if(e.response&&e.response.status === 403){
        setTimeout(()=>propsUpdateUser({}),2000);
      }else if(e.response&&e.response.status !== 200){
        setError("There seems to be a problem with the service at the momemt. Please try again later.");
      }else{
        setError("Something went wrong while reaching the API service. Are you connected to the internet?");
      }
    }

    refreshApplication();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [applicationId, propsUpdateUser, propsUser]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error !== '') {
          setError('');
        }
      }
    }, 3000);
  }, [error]);

  function getApplicationAvatarSrc(_applicationid){
    let randNum = Math.floor(Math.random()*1000) + 3;
    return ApiFunctions.getApiUri()+'/api/avatar/applications/'+_applicationid+'/?token='+propsUser.token+'&rand='+randNum
  }

  function updateApplicationStatus(status){
    setApplication((old)=>{
      return {
        ...old,
        status:status
      }
    })
  }

  return (
    !props.user.active||!props.user.mobile?
      <Grid item xs={12}>
        <Alert style={{marginBottom: 10}} severity='error'>
            Both your email and mobile must be verified to use this service
        </Alert>
      </Grid>
    :
    !authorized?
      <Grid item xs={12}>
        <Alert style={{marginBottom: 10}} severity='error'>
            {authorizedMessage}
        </Alert>
      </Grid>
    :

    <Container maxWidth="xl" className={classes.container}>
      {(error !== '') &&
        <Grid item xs={12}>
          <Alert style={{ marginBottom: 10 }} severity='error'>
            {error}
          </Alert>
        </Grid>
      }

      <Box style={{width:'100%'}} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box>
          <h2 style={{ padding: 0, margin: 0, flex: 1 }}>Admission Application for {application?.student?.name} </h2>
        </Box>
        <Box>
        
            <Chip
            variant='outlined'
            icon={<ListAltSharpIcon style={{color: edusim.admission_status_colors[application.status]}} />} 
            label={application.status} 
            style={{padding: '2px'}} />
          
        </Box>
      </Box>
      
      <ApplicationViewOnlyView
      setError={setError}
      application={application} 
      studentImageSrc={getApplicationAvatarSrc(application._id)} 
      user={propsUser} 
      eventFlowArray={eventFlowArray} 
      updateApplicationStatus={updateApplicationStatus} />
    
    </Container>

  );
}