import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputBase,
  ListItemIcon,
  Checkbox,
  Typography,
  Grid,
  Button,
  Box,
  FormControl,
  TextField,
  Avatar,
  CircularProgress,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Chip,
  Select,
  MenuItem,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import axios from 'axios';
import edusim from '../../config/edusim';
import moment from 'moment';
import { ApiFunctions } from '../../services/Api';

import {Controller, useForm, useWatch} from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import maleAvatarPlaceholder from '../../imgs/maleAvatarPlaceholder.png';
import Resizer from 'react-image-file-resizer';
import { useTabContext } from '@material-ui/lab';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';

const statusColors = {
  'Verified': "#32CD32",
  'Unverified': "#DC143C"
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    width: 300,
    minHeight:400,
    paddingBottom: theme.spacing(2)
  },
  mediaButton:{
    width: 300,
    padding:0, 
    border:0
  },
  media: {
    height: 300
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function getDateAge(dob, referenceDate= new Date()){
    let months = moment(referenceDate).diff(dob, "months", true);
    let years = months/12;
    return years;
}

function formatPercent(percent){
    return percent?percent.toLocaleString(undefined, {maximumFractionDigits:2})+"%":null
}
    
function roundAmount(amount){
    let frac = 100;
    return Math.round((amount+Number.EPSILON)*frac) / frac;
}

const NewStudentEnrollmentView = (props) =>{
    const possibleStudents = props.possibleStudents;
    const level = props.level;
    const term = props.term;
    const loading = props.loading;
    const enrollStudent = props.enrollStudent;
    
    const handleEnrollClick = (e, student)=>{
        enrollStudent(student, term);
    }

    return (
        <Grid 
        container 
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="baseline">
        {possibleStudents&&possibleStudents.map((student, key)=>(
        <Grid item xs={6} key={key}>
        <List style={{width:"100%", border:"1px solid #cccccc"}}>
        <ListItem style={{width:"100%"}} >
            <ListItemText id={'id'} primary={student.name}
              secondary={
                  'Ref Age: ' + 
                  Math.round((getDateAge(student.demographics.dateofbirth, term.year+ "-"+ level.age_ref_monthday)+ Number.EPSILON) * 100) / 100 + 
                  " / Real Age: " + Math.round((getDateAge(student.demographics.dateofbirth)+ Number.EPSILON) * 100) / 100} />
            <ListItemSecondaryAction>
              <IconButton
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={(e)=>handleEnrollClick(e, student)}>
                <AddIcon />
              </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        </List>
        </Grid>
       ))}
       {possibleStudents&&possibleStudents.length===0&&
        <Grid item xs={6}>All possible students are enrolled.</Grid>
       }
    </Grid>
    )
}

export default function LevelEnrollmentsView(props) {
    const classes = useStyles();
    const isMounted = React.useRef(null);
    const propsUpdateUser = props.updateUserInformation;
    const propsUser = props.propsUser;
    const level = props.level;
    const setError = props.setError;
    const yearsObject = props.yearsObject;
    const [selectedYear, setSelectedYear] = React.useState(2021);
    const [enrollmentObj, setEnrollmentObj] = React.useState({});
    const [newStudentEnrollmentVisible, setNewStudentEnrollmentVisible] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    

    React.useEffect(()=>{

        isMounted.current = true;
        
        const loadLevelEnrollments = () => {
          setLoading(true);
          ApiFunctions.getLevelEnrollments(propsUser, level._id, selectedYear).then(res=>{
            console.log(res);
            setEnrollmentObj(res);
          }).catch(e=>{
              setError("Cannot get level enrollments. Please try again later.");
          }).finally(()=>{
            setLoading(false);
          });
        }
    
        //load existing school
        if(level&&selectedYear&&level._id)
            loadLevelEnrollments();
    
        return () => {
          // executed when unmount
          isMounted.current = false;
        }
    
    }, [level, selectedYear, propsUser]);

    const handleAddEnrollmentClick = (e, _termid) =>{
        setNewStudentEnrollmentVisible((old)=>{
            return {...old, [_termid]: old[_termid]?!old[_termid]:true}
        });
    }


    function getEnrollmentsInTerm(_termid){
        const enrollmentsInTerm = enrollmentObj&&enrollmentObj.enrollments?enrollmentObj.enrollments.filter((en)=>en._termid===_termid):[];
        return enrollmentsInTerm;
      }

    const handleYearChange = (e) =>{
        let year = e.target.value;
        setSelectedYear(year);
    }

    function getPossibleStudentsInTerm (term) {

        const enrollmentsInTerm = enrollmentObj&&enrollmentObj.enrollments?enrollmentObj.enrollments.filter((en)=>en._termid===term._id):[];
        if(enrollmentsInTerm.length===0){
            return enrollmentObj.possibleStudents;
        }else{
            let possibleStudentsArray = [];
            enrollmentObj.possibleStudents.map((student)=>{
                !enrollmentsInTerm.some((en)=>en._studentid._id===student._id)&&(possibleStudentsArray.push(student));
            });
            return possibleStudentsArray;
        }
        //const possibleStudentsInTerm = possibleStudents.filter((ps)=>!e) enrollmentsInTerm;
    }

    function enrollStudent(student, term){
        setLoading(true);
        ApiFunctions.enrollStudentInLevel(propsUser, level._id, term._id, student._id).then(res=>{
        console.log(res);
        let tempEnrollments = [...enrollmentObj.enrollments];
        tempEnrollments.push({
            _id: res,
            _levelid: level._id,
            _studentid: student,
            _termid: term._id
        });
        setEnrollmentObj((old)=>{
            return {
                ...old,
                enrollments: tempEnrollments
            }
        })
        }).catch(e=>{
            setError("Cannot enroll student. Please try again later.");
        }).finally(()=>{
        setLoading(false);
        });
    }

    const handleSelectGroup = (e, term, enrollment) =>{
        const newGroupId = e.target.value==='Unassigned'?null:e.target.value;
        changeEnrollmentGroup(term, enrollment, newGroupId);
    }

    function changeEnrollmentGroup(term, enrollment, _groupid){
        console.log('changing to group...', _groupid);
        setLoading(true);
        ApiFunctions.changeEnrollmentGroup(propsUser, level._id, term._id, enrollment._id, _groupid).then(res=>{
            let tempEnrollments = [...enrollmentObj.enrollments];
            let tempEnrollmentIndex = tempEnrollments.findIndex((en)=>en._id===enrollment._id);
            let tempEnrollment = tempEnrollments[tempEnrollmentIndex];
            tempEnrollment._groupid = _groupid;
            setEnrollmentObj((old)=>{
                return {
                    ...old,
                    enrollments: tempEnrollments
                }
            })
        }).catch(e=>{
            setError("Cannot change group. Please try again later.");
        }).finally(()=>{
            setLoading(false);
        });
    }

    const handleRemoveEnrollmentClick = (e, term, enrollment) =>{
        console.log("removing..", enrollment._id);
        ApiFunctions.unenrollStudentInLevel(propsUser, level._id, term._id, enrollment._id).then(res=>{
            let remainingEnrollments = enrollmentObj.enrollments.filter((en)=>en._id!==enrollment._id);
            setEnrollmentObj((old)=>{
                return {
                    ...old,
                    enrollments: remainingEnrollments
                }
            })
        }).catch(e=>{
            setError("Cannot unenroll student. Please try again later.");
        }).finally(()=>{
            setLoading(false);
        });
    }

    function getDateOnly(isoDate){
      return isoDate?.toString().split("T")[0]
    }

    return (
        <React.Fragment>
        <Grid 
        container 
        spacing={3}
        direction="row"
        justify="space-between"
        alignItems="center">
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">Level Enrollments</Typography>
          </Grid>
          
          <Grid item>
              <Select
              variant='outlined'
              defaultValue={2021}
              onChange={(e) => handleYearChange(e)}>
              {yearsObject&&Object.keys(yearsObject).map((key, index)=>(
                  <MenuItem key={index} value={key}>
                      {key}
                  </MenuItem>
              ))}
              </Select>
          </Grid>
        </Grid>
        <Grid 
        container 
        spacing={3}
        direction="column"
        justify="flex-start"
        alignItems="baseline">
    
       {yearsObject[selectedYear]?.map((term, kt)=>{
          return(
          <Grid item style={{width:"100%"}} key={kt}>
            <Grid 
              container 
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="center">
              <Grid item><Typography component="h2" variant="h6">{term.name}</Typography></Grid>
              <Grid item>
                <IconButton
                  variant="contained"
                  disabled={loading}
                  color="primary"
                  onClick={(e)=>handleAddEnrollmentClick(e, term._id)}>
                    <AddIcon />
                </IconButton>
              </Grid>
              <Grid item alignSelf="flex-end">
                <Typography><b>{getEnrollmentsInTerm(term._id).length} of {level.max_capacity} max capacity ({formatPercent(roundAmount((getEnrollmentsInTerm(term._id).length/level.max_capacity)*100))} Utilization) </b></Typography>
              </Grid>
            </Grid>

            {newStudentEnrollmentVisible[term._id]&&
                <NewStudentEnrollmentView enrollStudent={enrollStudent} term={term} level={level} possibleStudents={getPossibleStudentsInTerm(term)} />
            }
            
            <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>Student Name</TableCell>
                    <TableCell>Date of Birth</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Unenroll</TableCell>
                    </TableRow>
                </TableHead>
            <TableBody>

            
            {getEnrollmentsInTerm(term._id).map((enrollment, key)=>(
         
            <TableRow key={key}>
                <TableCell>{enrollment?._studentid?.name || 'Not Found'}</TableCell>
                <TableCell>{getDateOnly(enrollment?._studentid?.demographics?.dateofbirth) || 'Not Found'}</TableCell>
                <TableCell>
                <Select 
                value={enrollment._groupid ? enrollment._groupid : 'Unassigned'}
                onChange={(e) => handleSelectGroup(e, term, enrollment)}>
                    <MenuItem value="Unassigned">
                        Unassigned
                    </MenuItem>
                    {enrollmentObj.groups.map((group, key)=>(
                        <MenuItem key={key} value={group._id}>
                            {group.name}
                        </MenuItem>
                    ))}
                </Select>    
                </TableCell>
                <TableCell>
                    <IconButton
                    variant="contained"
                    disabled={loading}
                    color="primary"
                    onClick={(e)=>handleRemoveEnrollmentClick(e, term, enrollment)}>
                        <CloseIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
           ))}
           </TableBody>
           </Table>
            
            </Grid>
          )
        })}
        </Grid>
        
        </React.Fragment>
    )
}