import React from 'react';
import {
    Typography,
    TextField,
    Grid,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Chip,
    Box,
    Input,
    OutlinedInput,
    Checkbox
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import edusim from '../config/edusim';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {DropzoneArea} from 'material-ui-dropzone';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DescIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContactSupportOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    paper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2)
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    control: {
      padding: theme.spacing(2),
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    studentAvatar:{
      backgroundColor: 'transparent'
    },
    wrapper: {
      position: 'relative',
    },
    inButtonProgress: {
      position: 'absolute',
      alignSelf: 'center',
      zIndex: 1,
    },
    uploadButton: {
      width:'100%'
    },
    dropZoneClass:{
      maxHeight: '200px'
    }
}));

const PlanView = (props) => {
    const classes = useStyles();

    const defaultPlan = props.selectedPlan && {
        ...props.selectedPlan
    }
    const [plan, setPlan] = React.useState(defaultPlan);
    //const [sessionDatesTimes, setSessionDatesTimes] = React.useState([]);
    const setPlanVisible = props.setPlanVisible;
    const setEditPlanVisible = props.setEditPlanVisible;
    const editPlanVisible = props.editPlanVisible;
    const selectedPlan = props.selectedPlan;
    const updatePlans = props.updatePlans;
    const addPlan = props.addPlan;
    const section = props.section;
    const plans = props.plans;

    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    const isMounted = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    //error handling states
    const [error, setError] = React.useState('');
    const [errors, setErrors] = React.useState({});
    const [errorMessages, setErrorMessages] = React.useState({});

    const [filesUploadProgress, setFilesUploadProgress] = React.useState(0);
    const [selectedDocuments, setSelectedDocuments] = React.useState([]);
    const [planAttachments, setPlanAttachments] = React.useState([]);
    const [attachmentsInfoLoading, setAttachmentsInfoLoading] = React.useState(false);
    const [removingFile, setRemovingFile] = React.useState({});
    const [dropZoneKey, setDropZoneKey] = React.useState(0);

    const [selectedSessions, setSelectedSessions] = React.useState(defaultPlan&&defaultPlan.sessions?defaultPlan.sessions:[]);
    /* const datesArray = props.calendarSettings.datesArray;
    const days = props.calendarSettings.days;
    const periodTimes = props.calendarSettings.periodTimes; */

    const handleSavePlan = (e) =>{
        setPlan(plan=>({...plan, validate:true, validateCallBack: (validPlan)=>{
            if(!validPlan){
              setError('Please fix the required information before proceeding.');
              setPlan(plan=>({...plan, validateCallBack: null}));
            }else{
              _savePlan();
            }
        }}));
    }

    const _savePlan = () =>{
        console.log(plan);
        const savedPlan = {...plan};
        savedPlan.sessions = selectedSessions;
        setLoading(true);
        axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/plans/"+plan._id, 
        {plan: {...savedPlan}}, {
            headers: {
            'x-access-token': propsUser.token
            }
        }).then(res => {
            console.log(res);
            updatePlans(savedPlan);
            setEditPlanVisible(false);
        }).catch(e => {
            if(e.response){
                if(isMounted.current){
                    setLoading(false);
                    setEditPlanVisible(false);
                }
                if(e.response.status === 403){
                    propsUpdateUser({});
                }
            }else{
                if(isMounted.current){
                    setLoading(false);
                    setError("Network connection might be lost");
                }                    
            }
        });
    }
    
    const handleAddPlan = (e) =>{
        setPlan(plan=>({...plan, validate:true, validateCallBack: (validPlan)=>{
            if(!validPlan){
              setError('Please fix the required information before proceeding.');
              setPlan(plan=>({...plan, validateCallBack: null}));
            }else{
              _addPlan();
            }
        }}));
    }

    const _addPlan = () => {
      const savedPlan = {...plan};
      savedPlan.sessions = selectedSessions;
      setLoading(true);
      axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/plans", 
      {plan: {_sectionid: section._id, _subjectid: section._subjectid, ...savedPlan}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        let tempPlan = {...savedPlan};
        tempPlan._id = res.data;
        addPlan(tempPlan);
        setLoading(false);
        setPlanVisible(false);
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost, ")
            }                    
        }
      });
     
    }

    const handleTextFieldChange = (e, fieldName) => {
        switch (fieldName) {
            case 'planTitle':
                setPlan({ ...plan, title: e.target.value.trim() });
                break;
            case 'planDesc':
                setPlan({ ...plan, description: e.target.value.trim() });
                break;
            default:
                console.log("default case");
        }
    }

    const handleCloseClick = (e) => {
        console.log(section.sessions);
        editPlanVisible ? setEditPlanVisible(false) : setPlanVisible(false);
    }

    /**File Uploads */

    const _uploadDocumentsProgress = (progressEvent) =>{
        const percentFraction = progressEvent.loaded / progressEvent.total;
        const percent = Math.floor(percentFraction * 100); 
        setFilesUploadProgress(percent);
        if(percent >= 100){
          setFilesUploadProgress(0);
        }
      }

    const _uploadPlanDocuments = () =>{
        console.log("Uploading plan documents");
    
        if(selectedDocuments.length > 0){
    
        let formData = new FormData();
    
        selectedDocuments.forEach((file)=>{
          formData.append('planDocuments[]', file, file.name);
        });
    
        //formData.append("_planid", plan._id);
    
        axios.post(edusim.api_base_uri+'/api/instructors/sections/'+section._id+'/plans/'+plan._id+'/uploadPlanAttachments', formData,{
          headers: {
            'content-type': 'multipart/form-data',
            'x-access-token': propsUser.token
          },
          onUploadProgress: _uploadDocumentsProgress
        }).then(res => {
          _getAttachmentsInfo();
          setFilesUploadProgress(0);
          setDropZoneKey(dropZoneKey + 1);
        }).catch(e => {
          if(e.response){
            if(e.response.status === 403){
              setError("Permission Problem - Seems that you might have logged off or your session has expired. Try to log in again.");
            }else if(e.response.status === 501){
              setError("Size Problem - Total uploads should be a maximum of 20 document and images and not more than 100MB combined");
            }
            else{
              setError("General Problem - There seems to be an issue with the API service at the moment. Please try again later.");
            }
          }
          else{
            setError("Network Problem - There seems to be an issue with the connection. Are you connected to the internet?");
          }
          setFilesUploadProgress(0);
          console.log(e);
        });
    
      }
      }
    
      const handleFilesSelect = (files) => {
        setSelectedDocuments(files);
      }

      const handleFilesUpload = () => {
        _uploadPlanDocuments();
      }
    
      const handleFileSelectError = (msg) => {
        setError(msg);
      }
    
      const _getAttachmentsInfo = () =>{
        setAttachmentsInfoLoading(true);
        axios.get(edusim.api_base_uri+'/api/instructors/sections/'+section._id+'/plans/'+plan._id+'/getPlanAttachmentsInfo', {
          headers: {
            'x-access-token': propsUser.token
          }
        }).then(res => {
        console.log(res.data);
         setAttachmentsInfoLoading(false);
         setPlanAttachments(res.data);
         updatePlanAttachmentIds(res.data);

        }).catch(e =>{
          setAttachmentsInfoLoading(false);
          console.log(e)
        });
      }

      const updatePlanAttachmentIds= (attachmentsInfo)=>{
        if(attachmentsInfo){
            let fileids = [];
            attachmentsInfo.map((fileinfo, key)=>{
                fileids.push(fileinfo._id);
            });
            console.log(fileids);
            setPlan({ ...plan, attachments: fileids });
            updatePlans({ ...plan, attachments: fileids });
         }
      }

      const removePlanAttachment = (fileid) =>{
        let newAttachments = planAttachments.filter(e=>e._id!==fileid);
        setPlanAttachments(newAttachments);
        updatePlanAttachmentIds(newAttachments);
      }

      const _removeUploadedFile = (fileid) =>{
        setRemovingFile(removingFile=> ({...removingFile, [fileid]: true}));
        axios.post(edusim.api_base_uri+'/api/instructors/sections/'+section._id+'/plans/'+plan._id+'/removePlanAttachment', {_fileid: fileid}, {
          headers: {
            'x-access-token': propsUser.token
          }
        }).then(res => {
          setRemovingFile(removingFile=> ({...removingFile, [fileid]: false}));
          removePlanAttachment(fileid);
        }).catch(e =>{
          setRemovingFile(removingFile=> ({...removingFile, [fileid]: false}));
          setError("Cannot remove the file at the moment, please try again later.");
        });
      }

      const handleFileRemove = (fileid) =>{
        _removeUploadedFile(fileid);
      }

      const handleFileDownload = (fileid) => {
        window.open(edusim.api_base_uri+'/api/instructors/sections/'+section._id+'/plans/'+plan._id+'/getPlanAttachment/'+fileid
        +"?token="+propsUser.token);
      }

      const handleSessionsSelectChange = (event) =>{
          console.log(event.target.value);
          const value = event.target.value;
          let newSessions = value;
          newSessions = (typeof value === 'string')?value.split(','):value;
          console.log(newSessions);
          setSelectedSessions((old)=>([...newSessions]));
          console.log(plan);
      }

      const theme = useTheme();

      function getStyles(session, theme) {
        //console.log(plan.sessions.findIndex(s=>s._id===session._id));
        return {
          fontWeight:
            selectedSessions.findIndex(s=>s._id===session._id) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
        };
      }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        getContentAnchorEl: () => null,
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
    },
    };

    React.useEffect(()=>{
        if(editPlanVisible) _getAttachmentsInfo();
    },[editPlanVisible]);

    React.useEffect(()=>{
        let validatedPlan = plan;
        
        const validateFields = () => {
          let planValid = true;
          
          if(!validatedPlan.title){
            planValid = false;
            setErrors(errors=> ({...errors, 'title': true}));
            setErrorMessages(errorMessages => ({...errorMessages, 'title': 'Required!'}));
          }else if(validatedPlan.title.length < 5){
            planValid = false;
            setErrors(errors=> ({...errors, 'title': true}));
            setErrorMessages(errorMessages=> ({...errorMessages, 'title': 'Minimum of 5 characters is required'}));
          }else{
            cancelErrors('title');
          }

         if(validatedPlan.validateCallBack)
            validatedPlan.validateCallBack(planValid);
        
        }
    
        const cancelErrors = (fieldName) => {
          setErrors(errors=>({...errors, [fieldName]: false}));
          setErrorMessages(errorMessages=>({...errorMessages, [fieldName]: null}));
        }
    
        if(validatedPlan?.validate){
            validateFields();
        }


      },[plan]);
    
    React.useEffect(()=>{
        setTimeout(()=>{
            if(error !== ''){
            setError('');
            }
        }, 3000);
    },[error]);
    
    function getSessionSelectTitle(sessionid){
        let foundIndex = section.sessions.findIndex(s=>s._id===sessionid);
        if(foundIndex>-1)
            return section.sessions[foundIndex].title + ' - ' + (section.sessions[foundIndex].actualSessionDateTime?section.sessions[foundIndex].actualSessionDateTime:'No Calendar Day')
        else
            return 'Not Found'
    }

    return (
        <React.Fragment>
            {(error !== '') &&
            <Alert style={{marginBottom: 10}} severity='error'>
                {error}
            </Alert>
            }
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {editPlanVisible ? 'Edit' : 'Add'} Plan
            </Typography>
           
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        required
                        error={errors['title']? true: false}
                        helperText={errorMessages['title']? errorMessages['title']: null}
                        onChange={(e) => handleTextFieldChange(e, 'planTitle')}
                        defaultValue={selectedPlan ? selectedPlan.title : ''}
                        label="Plan Title/Topic (required)"
                        variant="outlined" 
                        fullWidth
                        autoFocus>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        error={errors['description']? true: false}
                        helperText={errorMessages['description']? errorMessages['description']: null}
                        onChange={(e) => handleTextFieldChange(e, 'planDesc')}
                        defaultValue={selectedPlan ? selectedPlan.description : ''}
                        label="Plan Description"
                        variant="outlined" 
                        fullWidth
                        >
                    </TextField>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                <FormControl fullWidth>
                    {/* <InputLabel id="sessionsLabel">Sessions</InputLabel> */}
                    <Select
                    //labelId="sessionsLabel"
                    label="Sessions"
                    id="sessionsMultiple"
                    multiple
                    value={selectedSessions}
                    onChange={handleSessionsSelectChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => selected.map(sessionid=>getSessionSelectTitle(sessionid)).join(", ")}
                    /* renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value._id} label={value.title + ' - ' + (value.actualSessionDateTime?value.actualSessionDateTime:'No Calendar Day')} />
                        ))}
                        </Box>
                    )} */

                    MenuProps={MenuProps}
                    >
                        {/* <MenuItem
                        key={session._id}
                        value={session}
                        style={getStyles(session, theme)}
                        >
                        {session.title}
                        </MenuItem> */}
                    {section.sessions.map((session, key) => (
                        <MenuItem key={session._id} value={session._id}>
                            {<Checkbox checked={selectedSessions.findIndex(s=>s===session._id) > -1} />}
                            <ListItemText primary={session.title +'-'+ (session.actualSessionDateTime?session.actualSessionDateTime:'No Calendar Day') } />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Grid>
            </Grid>

            <Grid container 
                direction="row"
                justify="flex-end"
                alignItems="flex-end" spacing={3}>
                <Grid item>
                    <Button
                        onClick={(e) => handleCloseClick(e)}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => editPlanVisible ? handleSavePlan(e):handleAddPlan(e)}>
                        {editPlanVisible ? "Save" : "Add"} Plan
                    </Button>
                </Grid>
            </Grid>


            {editPlanVisible&&
                <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                <DropzoneArea
                    key={dropZoneKey}
                    dropzoneClass={classes.dropZoneClass}
                    onChange={handleFilesSelect}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    filesLimit={20}
                    maxFileSize={100000000}
                    showAlerts={false}
                    previewText="Selected Files:"
                    previewGridProps={{container: {alignItems:'flex-start', wrap:'wrap'},item:{xs:'auto',sm:'auto',md:'auto',lg:'auto'}}}
                    getFileLimitExceedMessage={(limit) => handleFileSelectError('You can only upload '+limit+ ' files at a time.')}
                />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button color="primary" variant="outlined" 
                    className={classes.uploadButton} 
                    onClick={handleFilesUpload} disabled={filesUploadProgress>0?true:false}>UPLOAD SELECTED</Button>
                    {filesUploadProgress>0 && <LinearProgress variant="determinate" value={filesUploadProgress} />}
                    <List dense={false}>
                    {!attachmentsInfoLoading && planAttachments.map((obj, key)=>
                    (<ListItem button key={key} onClick={()=>handleFileDownload(obj._id)} disabled={removingFile[obj._id]?true:false}>
                        <ListItemAvatar>
                        <Avatar>
                            <DescIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                        primary={obj.filename}
                        primaryTypographyProps={{ noWrap: true }}
                        secondary={obj.contentType + " ("+ Math.floor(obj.length/1024)+" KB)"}
                        />
                        <ListItemSecondaryAction>
                        {removingFile[obj._id] && <CircularProgress />}
                        {!removingFile[obj._id] &&
                        <IconButton onClick={()=>handleFileRemove(obj._id)} edge="end" aria-label="delete">
                            <DeleteIcon />
                        </IconButton>}
                        </ListItemSecondaryAction>
                    </ListItem>

                    ))} 
                    {attachmentsInfoLoading && <CircularProgress size={50} className={classes.inButtonProgress} />} 
                    </List>
                </Grid>
                </Grid>
            }
            
           
        </React.Fragment>
    )
}

export default PlanView;