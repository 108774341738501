import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "@reach/router";
import {
  Lock,
  Dashboard,
  EventAvailable,
  ViewList, 
  Work,
  Person,
  PeopleAlt,
  Apartment,
  CalendarTodayTwoTone,
  Description
} from '@material-ui/icons';

function ListItemLink(props) {
  const { icon, primary, to, selected, setActiveLoc } = props;
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} onClick={()=>setActiveLoc(to)}
        />
      )),
    [to, setActiveLoc],
  );

  return (
    <li>
      <ListItem button component={CustomLink} selected={selected}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export const MainListItems = (props) =>{
  const [activeLoc, setActiveLoc] = React.useState(window.location.pathname);
  return (
  <div>

    <ListItemLink to="/" primary="Dashboard" icon={<Dashboard />} 
    selected={ activeLoc ==='/' ? true:false} setActiveLoc={setActiveLoc}
    />

    <ListItemLink to="/users" primary="System Users" icon={<PeopleAlt />} 
    selected={ activeLoc ==='/users' ? true:false} setActiveLoc={setActiveLoc}
    />

    <ListItemLink to="/schools" primary="Manage Schools" icon={<Apartment />} 
    selected={ activeLoc ==='/schools' ? true:false} setActiveLoc={setActiveLoc}
    />

    <ListItemLink to="/levels" primary="Manage Levels" icon={<ViewList />} 
    selected={ activeLoc ==='/levels' ? true:false} setActiveLoc={setActiveLoc}
    />

    <ListItemLink to="/applications" primary="Admissions" icon={<Description />} 
    selected={ activeLoc ==='/applications' ? true:false} setActiveLoc={setActiveLoc}
    />

    {/* <ListItemLink to="/subjects" primary="My Subjects" icon={<Work />} 
    selected={ activeLoc ==='/subjects' ? true:false} setActiveLoc={setActiveLoc}
    /> */}

    <ListItemLink to="/profile" primary="Profile" icon={<Person />} 
    selected={ activeLoc ==='/profile' ? true:false} setActiveLoc={setActiveLoc}
    />

    <ListItem button
    onClick={()=>props.updateUserInformation({})}>
      <ListItemIcon>
        <Lock />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>

  </div>
);
}