import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "@reach/router";

import axios from 'axios';
import edusim from '../../config/edusim';


import Alert from '@material-ui/lab/Alert';

import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import EditIcon from '@material-ui/icons/Edit';
import UserIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import userAvatarPlaceholder from '../../imgs/maleAvatarPlaceholder.png';

//import data from '../config/data';
import { 
  FormControl,
  TextField,
  Button,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Grid,
  Tooltip,
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
 } from '@material-ui/core';

 import { ApiFunctions } from '../../services/Api';
 
 import {Controller, useForm, useWatch} from 'react-hook-form'
 import { yupResolver } from "@hookform/resolvers/yup";
 import * as Yup from "yup";
import { AppsOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    width: 300,
    height:550,
    paddingBottom: theme.spacing(2)
  },
  media: {
    height: 300
  },
  paper: {
    height: 320,
    width: 270,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  }
}));

function ListItemLink(props) {
  const { padText, primary, secondary, to } = props;
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={CustomLink} style={{paddingLeft: padText}}>
        {/* <ListItemIcon>{icon}</ListItemIcon> */}
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    </li>
  );
}

export default function SchoolLevelsList(props) {
  
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const authorizedMessage = "You are not authorized to use this service. Only system admins have access to level management.";
  const [authorized, setAuthorized] = React.useState(true);
  const [schools, setSchools] = React.useState([]);
  const [levels, setLevels] = React.useState([]);
  const [structures, setStructures] = React.useState([]);
  const [showDiscontinued, setShowDiscontinued] = React.useState(false);

  const colorsAvailable = 
  ["#d0fffe",
  "#fffddb",
  "#e4ffde",
  "#ffd3fd",
  "#ffe7d3"];

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
 
  const classes = useStyles();

  function compareLevels(a, b){
    if ( a.max_age < b.max_age ){
      return -1;
    }
    if ( a.max_age > b.max_age ){
      return 1;
    }
    return 0;
  }

  React.useEffect(() => {

    isMounted.current = true;

    const refreshSchoolLevels = () => {
        setLoading(true);
        Promise.all([
          ApiFunctions.listSchools(propsUser),
          ApiFunctions.getAllStructures(propsUser),
          ApiFunctions.getAllLevels(propsUser)
        ]).then(([schools, structures, levels]) => {
          //console.log(res);
          setAuthorized(true);
          setSchools(schools);
          setStructures(structures);
          levels.sort(compareLevels);
          setLevels(levels);
        }).catch(e=>{
          handleErrors(e);
        }).finally(()=>{
          isMounted.current&&setLoading(false);
        });
    }

    const handleErrors = (e) =>{
      console.log(e);
      if(e.response&&e.response.status === 401){
        setAuthorized(false);
      }else if(e.response&&e.response.status === 403){
        setTimeout(()=>propsUpdateUser({}),2000);
      }else if(e.response&&e.response.status !== 200){
        setError("There seems to be a problem with the service at the momemt. Please try again later.");
      }else{
        setError("Something went wrong while reaching the API service. Are you connected to the internet?");
      }
    }

    refreshSchoolLevels();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [propsUpdateUser, propsUser]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error !== '') {
          setError('');
        }
      }
    }, 3000);
  }, [error]);


  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("School name is required").min(5),
    telephone: Yup.string().notRequired().matches(phoneRegex,{ excludeEmptyString: true, message:"Must be a valid international mobile number like +97377887799"}),
    translated_name: Yup.string(),
    address: Yup.string(),
    license_number: Yup.string()
  });

  const {
    handleSubmit,
    reset,
    errors: fieldsErrors,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {name:'', translated_name:'', address:'', telephone:'', license_number:''},
    resolver: yupResolver(validationSchema),
  });


  function onSubmit(data){
    //let userToSave = {...user, ...data};
    /* console.log(data);
    addSchool(data); */
  }
  
  /* function addSchool(schoolToAdd){
    setLoading(true);
    ApiFunctions.saveSchool(propsUser, schoolToAdd).then((res)=>{
      setAddFormVisible(false);
      schoolToAdd._id=res;
      let tempSchools = [...schools];
      tempSchools.push(schoolToAdd);
      setSchools(tempSchools);
    }).catch((e)=>{
      setError("Cannot add school at this time.");
    }).finally(()=>{
      setLoading(false);
    });
  } */

  function getRootStructures(){
    let rootStructs = structures.filter(s=>s._parentstructid===null);
    return rootStructs;
  }
  
  function getChildren(structure){
    let childStructs = structures.filter(s=>s._parentstructid===structure._id);
    return childStructs;
  }

  function getNumberOfLevels(_schoolid){
    let levelsInSchool = levels.filter(l=>l._schoolid===_schoolid);
    return levelsInSchool.length;
  }
  
  function getRootStructures(_schoolid){
    let rootStructs = structures.filter(s=>s._schoolid===_schoolid&&s._parentstructid===null);
    return rootStructs;
  }
  
  function getChildren(structure){
    let childStructs = structures.filter(s=>s._parentstructid===structure._id);
    return childStructs;
  }

  function isEndingNode(structure){
    let childStructs = structures.filter(s=>s._parentstructid===structure._id);
    return childStructs.length===0?true:false;
  }

  function getLevelsInStructure(structure){
    let levelsInStruct = levels.filter(l=>l._structid===structure._id);
    return levelsInStruct;
  }
  
  function getDiscontinuedLevels(){
    let discontinuedLevels = levels.filter(l=>l.discontinued===true);
    return discontinuedLevels;
  }

  const handleAddLevelClick = (e, structure, school)=>{
    let newLevel = {
      name: "New Level",
      _structid: structure._id,
      _schoolid: school._id
    }
    setLoading(true);
    ApiFunctions.saveSchoolLevel(propsUser, newLevel).then((res)=>{
      newLevel._id = res;
      let tempLevels = [...levels];
      tempLevels.push(newLevel);
      setLevels(tempLevels);
    }).catch((e)=>{
      setError("Cannot add a new level. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
  }

  return (
    !props.user.active||!props.user.mobile?
      <Grid item xs={12}>
        <Alert style={{marginBottom: 10}} severity='error'>
            Both your email and mobile must be verified to use this service
        </Alert>
      </Grid>
    :
    !authorized?
      <Grid item xs={12}>
        <Alert style={{marginBottom: 10}} severity='error'>
            {authorizedMessage}
        </Alert>
      </Grid>
    :

    <Container maxWidth="xl" className={classes.container}>
    <Box>
    {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
    </Box>
    <Grid 
    container 
    spacing={3}
    direction="row"
    justify="flex-start"
    alignItems="center">
        <Grid item>
            <Typography component="h2" variant="h6" color="primary" >School Levels</Typography>
        </Grid>
    </Grid>
    
    {(loading ? Array.from(new Array(3)) :(schools&&schools)).map((school, key) => (
     school? <Accordion key={key} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
      >
          {school.name} ({getNumberOfLevels(school._id)})
      </AccordionSummary>
      <AccordionDetails>
      <Grid 
          container 
          spacing={3}
          direction="column"
          justify="flex-start"
          alignItems="baseline">
          <Grid item>
            <Button onClick={(e)=>{showDiscontinued?setShowDiscontinued(false):setShowDiscontinued(true)}}>Show Discontinued Levels</Button>
              {showDiscontinued&&
              
                  
                  <List style={{ width: '100%', border:"solid 1px #000000"}}>
                    {getDiscontinuedLevels().map((level, lkey)=>(
                      <ListItemLink key={lkey} to={"/levels/"+level._id} primary={level.name} secondary={level.min_age+'-'+level.max_age+' Yrs of Age / '+level.max_capacity+' Max Students'} padText={60} />
                    ))}
                  
                  </List>
                  
              }
          </Grid>
            
 
        <Grid item>
        <Grid 
          container 
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="baseline">
          {structures&&structures.length>0&&getRootStructures(school._id).map((struct, key)=>(
            <Grid key={key} item style={{minWidth: 360 }}>
            <List style={{ width: '100%', border:"solid 1px #000000"}}>
            <ListItem>
                <ListItemText id={'id'} primary={struct.name} />
            </ListItem>
            {getChildren(struct).map((child, key)=>{
              return(
                <React.Fragment key={key}>
                <ListItem style={{ paddingLeft: 40}}>
                  <ListItemText id={'id'} primary={child.name}/>
                  {isEndingNode(child._id)&&
                  <ListItemSecondaryAction>
                    <IconButton
                    variant="contained"
                    disabled={loading}
                    color="primary"
                    onClick={(e)=>handleAddLevelClick(e, child, school)}>
                      <AddIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                  }
                </ListItem>
                {getLevelsInStructure(child).map((level, key)=>{
                  return(
                      !level.discontinued&&
                      <ListItemLink key={key} to={"/levels/"+level._id} primary={level.name} secondary={(level.min_age||'N/A')+'-'+(level.max_age||'N/A')+' Yrs of Age / '+(level.max_capacity||'N/A')+' Max Students'} padText={60}/>
                )})}
                {getChildren(child).map((subchild, key)=>{
                  return(
                    <React.Fragment key={key}>
                    <ListItem key={key} style={{ paddingLeft: 60}}>
                      <ListItemText id={'id'} primary={subchild.name} />
                      <ListItemSecondaryAction>
                      <IconButton
                      variant="contained"
                      disabled={loading}
                      color="primary"
                      onClick={(e)=>handleAddLevelClick(e, subchild)}>
                        <AddIcon />
                      </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                        {getLevelsInStructure(subchild).map((sublevel, key)=>{
                        return(
                          !sublevel.discontinued&&
                          <ListItemLink to={"/levels/"+sublevel._id} primary={sublevel.name} secondary={sublevel.min_age+'-'+sublevel.max_age+' Yrs of Age / '+sublevel.max_capacity+' Max Students'} padText={60}/>
                        )})}
                    </React.Fragment>
                    
                )
                })}
                </React.Fragment>
                )
            })}
            </List>
            </Grid>
          ))}

        </Grid>
        
        </Grid>
        </Grid>
      </AccordionDetails>
      </Accordion>
      :
      <Accordion key={key} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Skeleton variant="rect" width={180} height={30} />
        </AccordionSummary>
      </Accordion>
      ))}
  </Container>
  );
}