import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputBase,
  ListItemIcon,
  Checkbox,
  Typography,
  Grid,
  Button,
  Box,
  FormControl,
  TextField,
  Avatar,
  CircularProgress,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Chip,
  Select,
  MenuItem,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  LinearProgress
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import axios from 'axios';
import edusim from '../../config/edusim';
import moment from 'moment';
import { ApiFunctions } from '../../services/Api';

import {Controller, useForm, useWatch} from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import maleAvatarPlaceholder from '../../imgs/maleAvatarPlaceholder.png';
import Resizer from 'react-image-file-resizer';
import { useTabContext } from '@material-ui/lab';
import { Close as CloseIcon, Add as AddIcon, EventAvailable, ControlPointDuplicateOutlined, SubscriptionsOutlined, SubjectOutlined } from '@material-ui/icons';
import { some } from 'async';
import Condition from 'yup/lib/Condition';
import roundToNearestMinutesWithOptions from 'date-fns/esm/fp/roundToNearestMinutesWithOptions/index.js';

const statusColors = {
  'Verified': "#32CD32",
  'Unverified': "#DC143C"
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    width: 300,
    minHeight:400,
    paddingBottom: theme.spacing(2)
  },
  sectionPeriodItem:{
    position: 'relative',
    display: 'flex',
    //display: 'inline-block',
    flexDirection: 'column',
    border: '1px solid rgba(0, 0, 0, 0.125)',
    borderRadius: '0.25rem',
    padding: 5 
  },
  sectionPeriodsSlot:{
    verticalAlign:'top',
    minWidth: 70,
    height: 100
  },
  itemText:{
    fontSize: 12
  },
  noConflictText:{
    fontSize: 16,
    color: 'green'
  },
  conflictText: {
    fontSize: 16,
    color: 'red'
  },
  mediaButton:{
    width: 300,
    padding:0, 
    border:0
  },
  media: {
    height: 300
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

/**
  Helper Functions
**/
function fisherYatesShuffle(arr){
  var i = arr.length;
  while (--i) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = arr[j];
      arr[j] = arr[i];
      arr[i] = temp;
  }
  return arr;
}

//helper function to get new unique ids for the objects created
const ID = function () {
// Math.random should be unique because of its seeding algorithm.
// Convert it to base 36 (numbers + letters), and grab the first 9 characters
// after the decimal.
return Date.now().toString(36) + '_' + Math.random().toString(36).substr(2, 9);
};

function getDateAge(dob, referenceDate= new Date()){
    let months = moment(referenceDate).diff(dob, "months", true);
    let years = months/12;
    return years;
}

function formatPercent(percent){
    return percent?percent.toLocaleString(undefined, {maximumFractionDigits:2})+"%":null
}
    
function roundAmount(amount){
    let frac = 100;
    return Math.round((amount+Number.EPSILON)*frac) / frac;
}

const InstructorAssignmentView = (props) =>{
  const {groups, subjects, level, saveAssignments, setInstructorAssignmentVisible, loading, aiLoading} = props;

  const [instructorAssignments, setInstructorAssignments] = React.useState([...level.last_instructor_assignments]);

  const handleSelectAssignment = (e, group, subject) =>{
    console.log(e.target.value, group, subject, instructorAssignments);
    let currentAssignmentIndex = instructorAssignments.findIndex((a)=>a._groupid===group._id&&a._subjectid===subject._id);
    let currentAssignments = [...instructorAssignments];
    console.log(currentAssignmentIndex);
    if(currentAssignmentIndex>-1){
      currentAssignments[currentAssignmentIndex]._instructorid = e.target.value;
    }else{
      let newAssignment = {
        _instructorid: e.target.value,
        _subjectid: subject._id,
        _groupid: group._id
      }
      currentAssignments.push(newAssignment);
    }
    setInstructorAssignments(currentAssignments);
  }

  function getAssignmentValue(group, subject){
    const assignmentIndex = level.last_instructor_assignments.findIndex((a)=>a._groupid===group._id&&a._subjectid===subject._id);
    return assignmentIndex>-1?level.last_instructor_assignments[assignmentIndex]._instructorid:'unassigned'
  }

  function getPossibleInstructors(subject){
    let possibleInstructors = [];
    subject&&subject.instructors&&subject.instructors.map((instructor)=>{
      const instructorIndex = level.instructors.findIndex((i)=>i._id===instructor._instructorid);
      instructorIndex>-1&&possibleInstructors.push(level.instructors[instructorIndex]);
    });
    return possibleInstructors;
  }

  return (
    <Fragment>
      <Grid 
        container 
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="center">
          <Grid item>
            <IconButton
              variant="contained"
              color="primary"
              onClick={()=>setInstructorAssignmentVisible(false)}>
                <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">Instructor Assignment</Typography>
          </Grid>

        </Grid>
    <Table>
      <TableHead>
          <TableRow>
          <TableCell><b>Subject/Group</b></TableCell>
          {groups&&groups.map((group, key)=>(
            <TableCell key={key}>{group.name}</TableCell>
          ))}
          </TableRow>
      </TableHead>
      <TableBody>
            {subjects&&subjects.map((subject, key)=>(
            <TableRow key={key}>
                <TableCell>{subject.short_name}</TableCell>
                
                {groups&&groups.map((group, key)=>(
                  <TableCell key={key}>
                    <Select
                    variant='outlined'
                    fullWidth
                    defaultValue={level.last_instructor_assignments?getAssignmentValue(group, subject):'unassigned'}
                    onChange={(e) => handleSelectAssignment(e, group, subject)}>
                        <MenuItem value={'unassigned'}>
                            Unassigned
                        </MenuItem>
                        {getPossibleInstructors(subject).map((instructor, key)=>(
                            <MenuItem key={key} value={instructor&&instructor._id}>
                                {instructor&&instructor.nickname}
                            </MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                ))}
               
            </TableRow>
           ))}
           </TableBody>
           </Table>
            <Grid
            container
            spacing={3}
            style={{ marginTop: 10 }}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={loading||aiLoading}
                onClick={()=>saveAssignments(instructorAssignments)}
              >
                Save Assignments
              </Button>
            </Grid>
        </Grid>
        </Fragment>

  )

}

const PeriodsView = (props) =>{
    const scheduleObj = props.scheduleObj;
    const level = props.level;
    const loading = props.loading;
    const aiLoading = props.aiLoading;
    const enrollStudent = props.enrollStudent;

    const addPeriod = props.addPeriod;
    const getPeriodsCount = props.getPeriodsCount;
    
    const handleAddPeriodClick = (e, subject, group)=>{
        addPeriod(subject, group);
    }

    return (
        <Grid 
        container 
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="baseline">
        {scheduleObj&&scheduleObj.groups.map((group, key)=>(
          scheduleObj&&scheduleObj.subjects.map((subject, skey)=>(
          <Grid item xs={6} md={4} lg={3} xl={2}  key={skey+''+key}>
          <List style={{width:"100%", border:"1px solid #cccccc"}}>
          <ListItem style={{width:"100%"}} >
              <ListItemText id={'id'} primary={group.name}
                secondary={subject.short_name + " ("+ getPeriodsCount(group, subject) + ")"} />
              <ListItemSecondaryAction>
                <IconButton
                variant="contained"
                disabled={loading||aiLoading}
                color="primary"
                onClick={(e)=>handleAddPeriodClick(e, subject, group)}>
                  <AddIcon />
                </IconButton>
              </ListItemSecondaryAction>
          </ListItem>
          </List>
          </Grid>
          ))
        
       ))}
    </Grid>
    )
}

const SectionPeriodItem = (props) =>{
  const {groupShortName, groupId, subjectShortName, instructorShortName, bgColor, slotSection, handleItemDrag, handleItemDelete, loading, aiLoading} = props;
  const classes = useStyles();

  return (
    <li className={classes.sectionPeriodItem} 
        style={{backgroundColor: bgColor}} 
        draggable={true} 
        onDragStart={(e)=>handleItemDrag(e, slotSection)}
        data-groupid={groupId}
        >
      <Grid 
        container 
        spacing={1}
        direction="row"
        justify="space-between"
        alignItems="center">
          <Grid item>
            <Typography className={classes.itemText}> {groupShortName} <br></br> {subjectShortName}  <br></br> {instructorShortName}</Typography>
          </Grid>
          <Grid item>
            <IconButton
                variant="contained"
                disabled={loading||aiLoading}
                color="primary"
                onClick={(e)=>handleItemDelete(e, slotSection._id)}>
                  <CloseIcon />
              </IconButton>
          </Grid>
      </Grid>
     

      
    </li>
  )
}

export default function LevelScheduleView(props) {
    const classes = useStyles();
    const isMounted = React.useRef(null);
    const propsUpdateUser = props.updateUserInformation;
    const propsUser = props.propsUser;
    const level = props.level;
    const setLevel = props.setLevel;
    const setError = props.setError;
    const yearsObject = props.yearsObject;
    const [selectedYear, setSelectedYear] = React.useState(null);
    const [selectedTermId, setSelectedTermId] = React.useState(null);
    const [scheduleObj, setScheduleObj] = React.useState({});
    const [groupSlots, setGroupSlots] = React.useState({});
    const [deletedPeriods, setDeletedPeriods] = React.useState([]);
    const [instructorSchedules, setInstructorSchedules] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [periodsVisible, setPeriodsVisible] = React.useState(false);
    const [aiLoading, setAiLoading] = React.useState(false);
    const [instructorAssignmentVisible, setInstructorAssignmentVisible] = React.useState(false);

    const days = [0,1,2,3,4,5];
    const periods = [0,1,2,3,4,5,6];
    const colorsAvailable = ["#d0fffe",
                        "#fffddb",
                        "#e4ffde",
                        "#ffd3fd",
                        "#ffe7d3"];

  function getEmptyGroupSlots(group, savedSections){
      var result = [];

      //switch i and j for period first
      for (let i = 0 ; i < periods.length; i++) {

          for (let j = 0; j < days.length; j++) {
              let slotObj = {
                day: j,
                period: i
              }
              let foundIndex = savedSections.findIndex((p)=>p._groupid===group._id&&p.day===j&&p.period===i);
              
              if(!(foundIndex>-1)){
                result.push(slotObj);
              }
                
          }
      }

      return result.reverse();
  }

    React.useEffect(()=>{

        isMounted.current = true;
        
        const loadLevelSchedule = () => {
          setLoading(true);
          ApiFunctions.getLevelSchedule(propsUser, level._id, selectedTermId).then(res=>{
            res.groups.forEach((group)=>{
              group.colorCode = colorsAvailable.pop();
            });
            setScheduleObj(res);
            console.log(res);
            ApiFunctions.getInstructorSchedules(propsUser, level._id, selectedTermId).then(res=>{
              setInstructorSchedules(res);
            })
          }).catch(e=>{
              setError("Cannot get level schedule. Please try again later.");
          }).finally(()=>{
            setLoading(false);
          });
        }
    
        //load existing school
        if(level&&selectedTermId&&level._id)
          loadLevelSchedule();

    
        return () => {
          // executed when unmount
          isMounted.current = false;
        }
    
    }, [level, selectedTermId, propsUser]);

    React.useEffect(()=>{
      console.log(Object.keys(yearsObject)[0]);
      let initialSelectedYear = Object.keys(yearsObject).length>0?Object.keys(yearsObject)[0]:null;
      let initialSelectedTermId = (initialSelectedYear&&yearsObject[initialSelectedYear].length>0)?yearsObject[initialSelectedYear][0]._id:null;
      setSelectedYear(initialSelectedYear);
      setSelectedTermId(initialSelectedTermId)
    }, [yearsObject])

    React.useEffect(()=>{
      setSelectedTermId((selectedYear&&yearsObject[selectedYear]&&yearsObject[selectedYear].length>0)?yearsObject[selectedYear][0]._id:null)
    }, [selectedYear])

    React.useEffect(()=>{
      scheduleObj&&scheduleObj.groups&&scheduleObj.sectionperiods&&scheduleObj.groups.map((group, key)=>{
        setGroupSlots((old)=>{
          return {
            ...old,
            [group._id]: getEmptyGroupSlots(group, scheduleObj.sectionperiods)
          }
        });
      });
      
    }, [scheduleObj]);

    const handleYearChange = (e) =>{
        let year = e.target.value;
        setSelectedYear(year);
    }

    const handleTermChange = (e) =>{
      let termId = e.target.value;
      setSelectedTermId(termId);
    }

    const handleDrop = (e, day, period) =>{
      e.preventDefault();
      const draggedSection = JSON.parse(e.dataTransfer.getData("text"));
      let foundSectionIndex = scheduleObj.sectionperiods.findIndex((sp)=>(sp._groupid===draggedSection._groupid)&&sp.day===day&&sp.period===period);
      if(foundSectionIndex>-1){
        console.log("found group in slot... switching"/* , originalDay, originalPeriod */);
        let tempSection = {...scheduleObj.sectionperiods[foundSectionIndex]};
        let tempDraggedSection = {...draggedSection};
        let tempSectionPeriods = [...scheduleObj.sectionperiods];
        const draggedFoundIndex = tempSectionPeriods.findIndex((sp)=>sp._groupid===draggedSection._groupid&&sp.day===draggedSection.day&&sp.period===draggedSection.period);
        tempSection.day = draggedSection.day;
        tempSection.period = draggedSection.period;
        tempDraggedSection.day= day;
        tempDraggedSection.period = period;
        tempSectionPeriods[foundSectionIndex] = tempSection;
        tempSectionPeriods[draggedFoundIndex] = tempDraggedSection;
        setScheduleObj((old)=>{
          return {
            ...old,
            sectionperiods: tempSectionPeriods
          }
        })
      }else{
        console.log("did not find group in slot... adding", day, period);
        let tempDraggedSection = {...draggedSection};
        tempDraggedSection.day = day;
        tempDraggedSection.period = period;
        let tempSectionPeriods = [...scheduleObj.sectionperiods];
        const draggedFoundIndex = tempSectionPeriods.findIndex((sp)=>sp._groupid===draggedSection._groupid&&sp.day===draggedSection.day&&sp.period===draggedSection.period);
        tempSectionPeriods[draggedFoundIndex] = tempDraggedSection;
        setScheduleObj((old)=>{
          return {
            ...old,
            sectionperiods: tempSectionPeriods
          }
        })
      }
    }

    const handleDragOver = (e) =>{
      e.preventDefault();
    }

    const handleItemDrag = (e, draggedSection)=>{
      console.log("dragged ... ", e.target.getAttribute("data-groupid"));
      e.dataTransfer.setData("text", JSON.stringify(draggedSection));
    }

    const handleAiSchdeduleClick = (e)=>{
      runDayScheduler();
    }

    const handleAddPeriodsClick = (e)=>{
      setPeriodsVisible(!periodsVisible);
    }

    function sectionCompare( a, b ) {
      if ( a._groupid < b._groupid ){
        return -1;
      }
      if ( a._groupid > b._groupid ){
        return 1;
      }
      return 0;
    }

    function getSlotSections (day, period){
      let slotSections = scheduleObj?.sectionperiods?.filter((sp)=>sp.day===day&&sp.period===period);
      let sortedSections = slotSections?slotSections.sort(sectionCompare):[];
      return sortedSections;
    }

    function getGroupShortName (_groupid){
      let foundGroupIndex = scheduleObj.groups.findIndex((grp)=>grp._id===_groupid);
      return scheduleObj.groups[foundGroupIndex].short_name;
    }

    function getGroupColor (_groupid){
      let foundGroupIndex = scheduleObj.groups.findIndex((grp)=>grp._id===_groupid);
      return scheduleObj.groups[foundGroupIndex].colorCode;
    }

    function getSubjectShortName (_subjectid){
      let foundSubjectIndex = scheduleObj.subjects.findIndex((sub)=>sub._id===_subjectid);
      return scheduleObj.subjects[foundSubjectIndex].short_name;
    }

    /**AI Functions - Worker got main load*/
    function runDayScheduler(){
      const worker = new window.Worker('/schedule-worker.js');
      setAiLoading(true);
      worker.postMessage({ scheduleObj, days, periods, instructorSchedules });
      worker.onerror = (err) => err;
      worker.onmessage = (e) => {
        const { sampleSolutions } = e.data;
        updateAISchedule(sampleSolutions);
        setAiLoading(false);
        worker.terminate();
      };

    }

    function updateAISchedule(sampleSolutions){
      let tempSectionPeriods = [...scheduleObj.sectionperiods];
      sampleSolutions.forEach((sampleSolution)=>{
        sampleSolution.forEach((slot)=>{
          if(slot.sections.length > 0){
            slot.sections.forEach((section)=>{
              let foundIndex = tempSectionPeriods.findIndex(e=>e._id===section._id);
              if(foundIndex>-1){
                let tempSection = {...tempSectionPeriods[foundIndex]};
                tempSection.day = slot.day;
                tempSection.period = slot.period;
                tempSectionPeriods[foundIndex] = {...tempSection};
              }
            });
          }
        })
      });

      setScheduleObj((old)=>{
        return {
          ...old,
          sectionperiods: tempSectionPeriods
        }
      });


    }

    function instructorConflictCount(){

      let conflictCount = 0;
      for(let i=0; i<days.length; i++){
        for(let j=0; j<periods.length; j++){
          
          let slotInstructors = [];
          let slotCount = {};
          let foundSections = scheduleObj?.sectionperiods?.filter(e=>e.day===i&&e.period===j);

          foundSections&&foundSections.forEach((section)=>{
            section._instructorid&&section._instructorid._id&&section._instructorid._id!=='unassigned'&&slotInstructors.push(section._instructorid._id);
          });

          slotInstructors.forEach((x)=> { slotCount[x] = (slotCount[x] || 0)+1; });
          
          for (const key in slotCount) {
            if(slotCount[key] > 1){
              conflictCount = conflictCount + (slotCount[key]-1);
            }
          }
          
        }
      }
      return conflictCount;
    }

    function instructorExternalConflictCount(){
      let count = 0;
        if(scheduleObj&& scheduleObj.sectionperiods){
          scheduleObj.sectionperiods.forEach( (sectionPeriod)=>{
            if(sectionPeriod._instructorid&&sectionPeriod._instructorid._id){
              count += getExternalConflictsCount(sectionPeriod._instructorid._id, sectionPeriod.day, sectionPeriod.period);
            }
          });
        }
      return count;
    }

    function getExternalConflictsCount(instructorid, day, period){
      let found = instructorSchedules.filter(e=>e._instructorid===instructorid&&e.day===day&&e.period===period);
      return found.length;
    }
  
    function getInstructorAssignment(subject, group){
      let instructorAssignmentIndex = level.last_instructor_assignments.findIndex(ia=>ia._subjectid===subject._id&&ia._groupid===group._id);
      return instructorAssignmentIndex>-1?level.last_instructor_assignments[instructorAssignmentIndex]:null;
    }

    function getInstructor(_instructorid){
      let instructorIndex = level.instructors.findIndex((i)=>i._id===_instructorid);
      return instructorIndex>-1?level.instructors[instructorIndex]:null;
    }

    function saveAssignments(instructorAssignments){
      console.log(instructorAssignments);
      ApiFunctions.saveInstructorAssignments(propsUser, level._id, selectedTermId, instructorAssignments).then((res)=>{
        setInstructorAssignmentVisible(false);
        setLevel((old)=>{
          return {
            ...old,
            last_instructor_assignments: instructorAssignments
          }
        });
        let tempSectionPeriods = [...scheduleObj.sectionperiods];
        tempSectionPeriods.map((period)=>{
          const instructorAssignmentIndex = instructorAssignments.findIndex((ai)=>ai._groupid===period._groupid&&ai._subjectid===period._subjectid);
          period._instructorid = {};
          period._instructorid._id = instructorAssignmentIndex>-1?instructorAssignments[instructorAssignmentIndex]?._instructorid:null;
          period.instructorShortName = instructorAssignmentIndex>-1?getInstructor(instructorAssignments[instructorAssignmentIndex]?._instructorid)?.nickname:null;

        });
        setScheduleObj((old)=>{
          return {
            ...old,
            sectionperiods: tempSectionPeriods
          }
        })
      });
    }

    /* function getNextDaySlot(subject, group, currentSlots){
      const lastSlot = currentSlots[currentSlots.length-1];
      let subjectIndex = scheduleObj.sectionperiods.findIndex((p)=>p._subjectid===subject._id&&p._groupid===group._id&&p.day===lastSlot.day);
      if(subjectIndex>-1){
        //fisherYatesShuffle(currentSlots);
      }
      return currentSlots.pop();
    } */

    function addPeriod(subject, group){
      //fisherYatesShuffle(groupSchedulesCont[sectionPeriod._groupid]);
      if(groupSlots&&groupSlots[group._id]&&groupSlots[group._id].length>0){
        
        let currentSlots = [...groupSlots&&groupSlots[group._id]];
        //fisherYatesShuffle(currentSlots);
        console.log(currentSlots);
        let slotToAdd = currentSlots.pop();
        //let slotToAdd = getNextDaySlot(subject, group, currentSlots);
       /*  setGroupSlots((old)=>{
          return {
            ...old,
            [group._id]: currentSlots
          }
        }); */
        let subjectInstructorAssignment = getInstructorAssignment(subject, group);
  
        let newPeriod = {
          _id: ID(),
          _subjectid: subject._id,
          _groupid: group._id,
          _instructorid: { _id: subjectInstructorAssignment?subjectInstructorAssignment?._instructorid:null},
          _termid: selectedTermId,
          instructorName: subjectInstructorAssignment&&subjectInstructorAssignment._instructorid
          &&getInstructor(subjectInstructorAssignment._instructorid)?.name?getInstructor(subjectInstructorAssignment._instructorid)?.name:null,
          instructorShortName: subjectInstructorAssignment&&subjectInstructorAssignment._instructorid
          &&getInstructor(subjectInstructorAssignment._instructorid)?.name?getInstructor(subjectInstructorAssignment._instructorid)?.nickname:null,
          day: slotToAdd.day,
          period: slotToAdd.period,
          new: true
        }
  
        let tempPeriods = [...scheduleObj.sectionperiods];
        tempPeriods.push(newPeriod);
        setScheduleObj((old)=>{
          return{
            ...old,
            sectionperiods: tempPeriods
          }
        });
  
        console.log(newPeriod);
      }
      
    }

    function clearSchedule(){
      setDeletedPeriods([...scheduleObj.sectionperiods.filter((p)=>!p.new)]);
      setScheduleObj((old)=>{
        return{
          ...old,
          sectionperiods: []
        }
      });
    }

    const handleItemDelete = (e, _id) =>{
      removePeriod(_id);
    }

    function removePeriod(_id){
      let deletedPeriodIndex = scheduleObj.sectionperiods.findIndex((p)=>p._id===_id);
      if(deletedPeriodIndex>-1){
        if(!scheduleObj.sectionperiods[deletedPeriodIndex].new){
          let tempDeletedPeriods = [...deletedPeriods];
          tempDeletedPeriods.push(scheduleObj.sectionperiods[deletedPeriodIndex]);
          setDeletedPeriods(tempDeletedPeriods);
        }
      }
      let remainingPeriods = [...scheduleObj.sectionperiods.filter((p)=>p._id!==_id)];

      setScheduleObj((old)=>{
        return{
          ...old,
          sectionperiods: remainingPeriods
        }
      });

    }

    function getPeriodsCount(group, subject){
      let periods = scheduleObj.sectionperiods.filter((p)=>p._groupid===group._id&&p._subjectid===subject._id);
      return periods.length;
    }

    function savePeriods(){
      let allSections = [];
      scheduleObj.sectionperiods.map((section)=>{
        console.log(section);
        allSections.push({
          ...section,
          _instructorid: section._instructorid&&section._instructorid._id?section._instructorid._id:null
        });
      });
      const newPeriods = allSections.filter((p)=>p.new);
      console.log('new',newPeriods);
      const existingPeriods = allSections.filter((p)=>!p.new);
      console.log('existing', existingPeriods);
      console.log('to be deleted', deletedPeriods);
      ApiFunctions.saveLevelSchedule(propsUser, level._id, existingPeriods, newPeriods, deletedPeriods).then((res)=>console.log(res));
    }


    return (
        <React.Fragment>
        {instructorAssignmentVisible?
        <InstructorAssignmentView
          setInstructorAssignmentVisible={setInstructorAssignmentVisible}
          saveAssignments={saveAssignments} 
          level={level}
          subjects={scheduleObj.subjects} 
          groups={scheduleObj.groups} 
          loading={loading}
          aiLoading={aiLoading} />
        :
        <>

        <Grid 
        container 
        spacing={3}
        direction="row"
        justify="space-between"
        alignItems="center">
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">Level Schedule</Typography>
          </Grid>
          <Grid item>
          <Grid 
              container 
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="center">
              <Grid item><Typography className={instructorConflictCount()===0?classes.noConflictText:classes.conflictText}>{instructorConflictCount()+' Instructor Conflicts in this Level'}</Typography></Grid>
              <Grid item><Typography className={instructorExternalConflictCount()===0?classes.noConflictText:classes.conflictText}>{instructorExternalConflictCount()+' Instructor Conflicts in other Levels'}</Typography></Grid>
            </Grid>
          </Grid>
          
          <Grid item>
            <Grid 
            container 
            spacing={2}
            direction="row"
            justify="flex-end"
            alignItems="center">
              <Grid item>
                <Select
                variant='outlined'
                value={selectedYear||''}
                onChange={(e) => handleYearChange(e)}>
                {yearsObject&&Object.keys(yearsObject).map((key, index)=>(
                    <MenuItem key={index} value={key}>
                        {key}
                    </MenuItem>
                ))}
                </Select>
              </Grid>
              <Grid item>
                <Select
                variant='outlined'
                value={selectedTermId||''}
                onChange={(e) => handleTermChange(e)}>
                {yearsObject[selectedYear]?.map((term, key)=>(
                    <MenuItem key={key} value={term._id}>
                        {term.name}
                    </MenuItem>
                ))}
                </Select>
              </Grid>
              
            </Grid>
          
          </Grid>

        </Grid>

        {!aiLoading&&
         <Grid 
          container 
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
            <Button onClick={()=>setInstructorAssignmentVisible(true)}>
              Instructor Assignment
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={(e)=>handleAiSchdeduleClick()}>Run AI Fit</Button>
          </Grid>
          <Grid item>
            <Button onClick={(e)=>clearSchedule()}>Clear Schedule</Button>
          </Grid>
          <Grid item>
            <IconButton
              variant="contained"
              disabled={loading||aiLoading}
              color="primary"
              onClick={(e)=>handleAddPeriodsClick(e)}>
                <AddIcon />
            </IconButton>
          </Grid>
         </Grid>
        }

        {aiLoading&&
          <>
          <Typography component="h2" variant="h6">Please be patient while finding possible solutions...</Typography>
          <LinearProgress />
          </>
        }

        {periodsVisible&& 
        <PeriodsView 
          getPeriodsCount={getPeriodsCount} 
          addPeriod={addPeriod} 
          scheduleObj={scheduleObj} 
          level={level} 
          loading={loading}
          aiLoading={aiLoading} 
        /> }
       
        <table style={{width:"100%", marginTop:10}}>
      <thead>
        <tr>
          <th>  </th>
          {periods.map((period, key)=>(
            <th scope="col" key={key}>P{period+1}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {days.map((day, key)=>(
        <tr key={key}>
          <th>
           {/*  <button class="btn btn-link pl-0 pr-0" onclick="copyFromPreviousDay({{@key}})"><i class="fa fa-copy"></i></button>
            <button class="btn btn-link pl-0 pr-0" onclick="shuffleDay({{@key}})"><i class="fa fa-random"></i></button> */}
            Day {day+1}
          </th>
          {periods.map((period, key)=>(
            <td key={key} onDrop={(e)=>handleDrop(e, day, period)} 
                onDragOver={(e)=>handleDragOver(e)} 
                data-period-index={period}
                data-day-index={day}
                data-group-id=""
                data-subject-id=""
                className={classes.sectionPeriodsSlot}
            >
              {(loading ? Array.from(new Array(3)) :getSlotSections(day, period)).map((slotSection, key)=>{
                return (
                slotSection?
                <SectionPeriodItem 
                  key={slotSection._id}
                  groupShortName={getGroupShortName(slotSection._groupid)}
                  groupId={slotSection._groupid}
                  subjectShortName={getSubjectShortName(slotSection._subjectid)}
                  instructorShortName={slotSection.instructorShortName||"Unassigned"}
                  bgColor={getGroupColor(slotSection._groupid)}
                  slotSection={slotSection}
                  handleItemDelete={handleItemDelete}
                  handleItemDrag={handleItemDrag}
                  loading={loading}
                  aiLoading={aiLoading}
                />
                :
                <Skeleton variant="rect" width={150} height={30} />)
              })}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
      {/* <Grid 
        container 
        spacing={3}
        direction="column"
        justify="flex-start"
        alignItems="baseline">
      
          <Grid item style={{width:"100%", marginTop: 4}}>
            <Grid 
              container 
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="center">
              <Grid item><Typography component="h2" variant="h6">{instructorConflictCount()+' Instructor Conflicts in this Level'}</Typography></Grid>
              <Grid item><Typography component="h2" variant="h6">{instructorExternalConflictCount()+' Instructor Conflicts in other Levels'}</Typography></Grid>
            </Grid>
          </Grid>
      </Grid> */}

        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading||aiLoading}
              onClick={()=>savePeriods()}
            >
              Save Schedule
            </Button>
          </Grid>
      </Grid>
      </>
      }
        </React.Fragment>
            
    )
}