import React from 'react';

import {
    Grid,
    IconButton, 
    Select, 
    MenuItem,
    Typography,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    Tooltip,
    makeStyles
} from '@material-ui/core';

import { 
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    Edit as EditIcon,     
} from '@material-ui/icons';

import FloatingActionButton from './FAB';

import AssignmentGradeEntry from '../views/AssignmentGradeEntry';
import AssignmentView from '../views/AssignmentView';
import axios from 'axios';
import edusim from '../config/edusim';

const AssignmentsView = (props) => {
    const [newAssignmentVisible, setNewAssignmentVisible] = React.useState(false);
    const [editAssignmentVisible, setEditAssignmentVisible] = React.useState(false);
    const [selectedAssignment, setSelectedAssignment] = React.useState(null);
    
    const section = props.section;
    const [currentAssignments, setCurrentAssignments] = React.useState(section.assignments);
    const [assignmentGradesVisible, setAssignmentGradesVisible] = React.useState(false);

    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    const isMounted = React.useRef(null);
  
    const [loading, setLoading] = React.useState(false);

    //error handling states
    const [error, setError] = React.useState('');

    const updateAssignments = (assignment) => {
        let foundIndex = currentAssignments ? currentAssignments.findIndex(e => e._id === assignment._id) : -1;
        if (foundIndex >= 0) {
            let tempArr = [...currentAssignments];
            tempArr[foundIndex] = assignment;
            setCurrentAssignments(tempArr);
        }
        props.updateSectionAssignments(assignment);
    }

    const addAssignment = (assignment) => {
        let tempArr = [...currentAssignments];
        tempArr.push(assignment);
        setCurrentAssignments(tempArr);
        props.addSectionAssignment(assignment);
    }
   
    const handleAssignmentGradesClick = (e, assignment) => {
        setAssignmentGradesVisible(true);
        setSelectedAssignment(assignment);
    }
   
    const handleAddAssignementClick = (e) => {
        newAssignmentVisible ? setNewAssignmentVisible(false) : setNewAssignmentVisible(true);
    }
   
    const handleEditAssignmentClick = (e, assignment) => {
        setSelectedAssignment(assignment);
        setEditAssignmentVisible(true);
    }

    const handleSelectCategory = (e, assignment) => {
        e.target.value==="Unassigned"?
        assignment['_categoryid'] = null
        :assignment['_categoryid'] = e.target.value;
        _saveAssignment(assignment);
        //updateAssignments(assignment);
    }

    const handleSelectInterval = (e, assignment) => {
        e.target.value==="Unassigned"?
        assignment['_intervalid'] = null
        :assignment['_intervalid'] = e.target.value;
        _saveAssignment(assignment);
        //updateAssignments(assignment);
    }

    const _saveAssignment = (assignment) =>{
      setLoading(true);
      axios.post(edusim.api_base_uri+"/api/instructors/sections/"+section._id+"/assignments/"+assignment._id, 
      {assignment: {...assignment}}, {
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        updateAssignments(assignment);
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
                //setNewAssignmentVisible(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost");
            }                    
        }
      });
    }

    return (
        <div>
            {editAssignmentVisible ?
                <AssignmentView
                    updateAssignments={updateAssignments}
                    editAssignmentVisible={editAssignmentVisible}
                    setEditAssignmentVisible={setEditAssignmentVisible}
                    selectedAssignment={selectedAssignment}
                    section={section}
                    propsUser={props.propsUser}
                    propsUpdateUser={props.propsUpdateUser}
                />
                :
                assignmentGradesVisible ?
                    <AssignmentGradeEntry
                        updateAssignments={updateAssignments}
                        section={section}
                        selectedAssignment={selectedAssignment}
                        setAssignmentGradesVisible={setAssignmentGradesVisible}
                        propsUser={props.propsUser}
                        propsUpdateUser={props.propsUpdateUser}
                    />
                    :
                    <React.Fragment>
                        <Grid 
                        container 
                        spacing={3}
                        direction="row"
                        justify="flex-start"
                        alignItems="center">
                            <Grid item>
                                <Typography component="h2" variant="h6" color="primary" >Current Assignments</Typography>
                            </Grid>
                            <Grid item>
                                <FloatingActionButton
                                    variant="contained"
                                    color="primary"
                                    clickHandler={handleAddAssignementClick} />
                            </Grid>
                        </Grid>
                        {newAssignmentVisible && 
                        <AssignmentView 
                            section={section} 
                            setNewAssignmentVisible={setNewAssignmentVisible}
                            addAssignment={addAssignment}
                            propsUser={props.propsUser}
                            propsUpdateUser={props.propsUpdateUser} />
                        }
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Name</b></TableCell>
                                    <TableCell><b>Points</b> </TableCell>
                                    <TableCell><b>Bonus Points</b></TableCell>
                                    <TableCell><b>Category</b></TableCell>
                                    <TableCell><b>Interval</b></TableCell>
                                    <TableCell><b>Actions</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentAssignments && currentAssignments.map((obj, key) => (
                                    <TableRow key={key}>
                                        <TableCell>{obj.name}</TableCell>
                                        <TableCell>{obj.points}</TableCell>
                                        <TableCell>{obj.bonus_points}</TableCell>
                                        <TableCell>
                                            <Select 
                                            defaultValue={obj._categoryid ? obj._categoryid : 'Unassigned'}
                                            onChange={(e) => handleSelectCategory(e, obj)}>
                                                <MenuItem value="Unassigned">
                                                    Unassigned
                                                </MenuItem>
                                                {section.categoryTemplate.categories.map((cat, key)=>(
                                                    <MenuItem key={key} value={cat._id}>
                                                        {cat.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                defaultValue={obj._intervalid ? obj._intervalid : 'Unassigned'}
                                                onChange={(e) => handleSelectInterval(e, obj)}>
                                                <MenuItem value="Unassigned">Unassigned</MenuItem>
                                                {section.intervals.map((int, key)=>(
                                                    <MenuItem key={key} value={int._id}>
                                                        {int.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Edit Assignment" aria-label="Edit Assignment">
                                                <IconButton variant="contained" color="primary" onClick={
                                                    (e) => handleEditAssignmentClick(e, obj)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Assignment Points" aria-label="Assignment Points">
                                                <IconButton variant="contained" color="primary" onClick={
                                                    (e) => handleAssignmentGradesClick(e, obj)
                                                }>
                                                    <AssignmentTurnedInIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </React.Fragment>
            }
        </div >
    )
};
export default AssignmentsView;