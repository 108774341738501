import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography,
  Grid,
  Button,
  Box,
  FormControl,
  TextField,
  Avatar,
  CircularProgress,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Chip
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

/* import data from '../config/data'; */

/* import AssignmentsView from './AssignmentsView';
import ScheduleView from './ScheduleView';
import GradeBookView from './GradeBookView';
import GradeSummaryView from './GradeSummaryView';
import PlanningView from './PlanningView';

import axios from 'axios';
import edusim from '../config/edusim'; */
import axios from 'axios';
import edusim from '../../config/edusim';
import { ApiFunctions } from '../../services/Api';

import {Controller, useForm, useWatch} from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import maleAvatarPlaceholder from '../../imgs/maleAvatarPlaceholder.png';
import Resizer from 'react-image-file-resizer';
import { useTabContext } from '@material-ui/lab';

const statusColors = {
  'Verified': "#32CD32",
  'Unverified': "#DC143C"
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    width: 300,
    minHeight:400,
    paddingBottom: theme.spacing(2)
  },
  mediaButton:{
    width: 300,
    padding:0, 
    border:0
  },
  media: {
    height: 300
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const UserOptionsList = ({selectedOption, handleSelectedOption}) =>{
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>User Options</Typography>
      <List component="nav" className={classes.enrollmentsListRoot}>
        <ListItem
          button
          selected={selectedOption === 1}
          onClick={(event) => handleSelectedOption(event, 1)}
        >
          <ListItemText 
          primary={'User Information'}
          secondary={''} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 2}
          onClick={(event) => handleSelectedOption(event, 2)}
        >
          <ListItemText 
          primary={'Admin Roles'}
          secondary={''} />
        </ListItem>
        
    </List>
    </React.Fragment>
  )
}

const UserInfoView = (props) =>{
  const user = props.user;
  const propsUser = props.propsUser;
  const setError = props.setError;
  const profileImageSrc = props.profileImageSrc;
  const setProfileImageSrc = props.setProfileImageSrc;
  const saveUserInfo = props.saveUserInfo;
  const saveUserActive = props.saveUserActive;

  const [changesMade, setChangesMade] = React.useState(false);
  const [piUploadProgress, setPiUploadProgress] = React.useState(0);

  const classes = useStyles();
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  const validationSchema = Yup.object().shape({
    realname: Yup.string().required("Real name is required").min(5),
    //email: Yup.string().required("Email is required").email("Invalid email"),
    mobile: Yup.string().notRequired().matches(phoneRegex,{ excludeEmptyString: true, message:"Must be a valid international mobile number like +97377887799"})
  });

  const {
    handleSubmit,
    reset,
    errors: fieldsErrors,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {realname:'', mobile:''},
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data){
    let userToSave = {...user, ...data};
    //console.log(userToSave);
    saveUserInfo(userToSave);
  }

  function getUserRoles(user){
    let roles = [];
    user._instructorid&&roles.push("Instructor");
    user._guardianid&&roles.push("Guardian");
    user.roles&&user.roles.length>0&&roles.push("Administrator");
    let rolesText = roles.join(", ");
    return rolesText;
  }

  React.useEffect(()=>{
    setValue("realname", (user.realname || '')) ;
    setValue("mobile", (user.mobile || ''));
  },[user]);


  const _uploadPiProgress = (progressEvent) =>{
    const percentFraction = progressEvent.loaded / progressEvent.total;
    const percent = Math.floor(percentFraction * 100); 
    console.log(percent);
    setPiUploadProgress(percent);
    if(percent >= 100){
      setPiUploadProgress(0);
    }
  }

  const handleImageUpload = (e, fieldName) =>{
   
    if(fieldName==='userAvatar' && e.target.files[0]){
      if(e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpeg'){
        console.log(e.target.files[0].name);
        const fd = new FormData();
        const files = [...e.target.files]
        try {
          Resizer.imageFileResizer(
            files[0],
            400,
            400,
            'JPEG',
            100,
            0,
            uri => {
                fd.append('avatar', uri, files[0].name);
                _uploadAvatar(fd);
            },
            'blob',
            200,
            200,
            );
        }   catch(err) {
                setError("Something went wrong preventing image upload at this time.");
        }
      }else{
        setError("Only jpeg and png files are allowed as images.");
      }
    }
  }

  const _uploadAvatar = (fd) =>{
    axios.post(edusim.api_base_uri+"/api/avatar/users/"+user._id, fd,{
      headers: {
        'content-type': 'multipart/form-data',
        'x-access-token': propsUser.token
      },
      onUploadProgress: _uploadPiProgress
    }).then(res=>{
      let randNum = Math.floor(Math.random()*1000) + 3;
      setProfileImageSrc(profileImageSrc+'&rand='+randNum);
    }).catch(e=>{
      setError("Could not upload the image at this time. Make sure your session is still active and try again.");
    });
  }

  const toggleUserActive = () =>{
    let userToSave;
    if(user.active){
      userToSave = {...user, active: false}
    }else{
      userToSave = {...user, active: true}
    }
    saveUserActive(userToSave);
  }

  const realnamewatch = useWatch({
    control,
    name: 'realname'
  });

  const mobilewatch = useWatch({
    control,
    name: 'mobile'
  });

  React.useEffect(()=>{
    realnamewatch!==user.realname?setChangesMade(true):setChangesMade(false);
  },[realnamewatch, user]);

  React.useEffect(()=>{
    mobilewatch!==user.mobile?setChangesMade(true):setChangesMade(false);
  },[mobilewatch, user]);

  return(
    <React.Fragment>

    <input
      accept="image/*"
      style={{display:'none'}}
      id="contained-button-file"
      type="file"
      onChange={(e)=> handleImageUpload(e, 'userAvatar')}
    />

      <Typography component="h2" variant="h6" color="primary" gutterBottom>User Information</Typography>

      <Grid container spacing={3}>

      <Grid item>
      <Card className={classes.cardRoot}>
        <CardHeader
          avatar={
            <Avatar aria-label="User" className={classes.avatar}>
              <PersonIcon />
            </Avatar>
          }
          title={user.realname}
          subheader={<div>{user.username}<br></br>{getUserRoles(user)}</div>}
        />
        
        <label htmlFor="contained-button-file">
        <Button className={classes.mediaButton} component="span" aria-label="upload picture">
        
        <CardMedia
          className={classes.media}
          component="img"
          src={profileImageSrc}
          onError= {(e) => { e.target.onerror=null;e.target.src = maleAvatarPlaceholder } }
          title={user.realname}

        />
        {<CircularProgress variant="determinate" size={50} value={piUploadProgress} className={classes.inButtonProgress} />}
        
        </Button>
        </label>
        
        <CardContent>
        <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-around">
          <Chip onClick={(e)=>toggleUserActive()} label={user.active?'Email Active':'Email Inactive'} 
          style={{backgroundColor:statusColors[props.user.active?'Verified':'Unverified'], color:"#ffffff"}} />
          <Chip label={user.mobile?'Mobile Active':'Mobile Inactive'} 
          style={{backgroundColor:statusColors[props.user.mobile?'Verified':'Unverified'], color:"#ffffff"}} />
        </Box>
        </CardContent>
      
      </Card>
      </Grid>
      
      <Grid item>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>

      <Grid container spacing={3}>

      <Grid item xs={12}>
        <FormControl
          fullWidth
          className={classes.margin}
          variant="outlined"
        >
          <Controller
            name="realname"
            control={control}
            defaultValue=""
            as={
              <TextField
                name="realname"
                label="Real Name"
                variant="outlined"
                fullWidth
                error={fieldsErrors.realname ? true : false}
                helperText={
                  fieldsErrors.realname ? fieldsErrors.realname.message : null
                }
              ></TextField>
            }
          ></Controller>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl
          fullWidth
          //className={classes.margin}
          variant="outlined"
        >
          <Controller
            name="mobile"
            control={control}
            defaultValue=""
            as={
              <TextField
                name="mobile"
                label="Mobile"
                variant="outlined"
                fullWidth
                error={fieldsErrors.mobile ? true : false}
                helperText={
                  fieldsErrors.mobile ? fieldsErrors.mobile.message : null
                }
              ></TextField>
            }
          ></Controller>
        </FormControl>
      </Grid>
      
      </Grid>
      
      <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          {/* <Grid item>
            <Button onClick={(e) => reset()}>Cancel</Button>
          </Grid> */}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!changesMade}
              onClick={handleSubmit(onSubmit)}
            >
              Save User
            </Button>
          </Grid>
      </Grid>

      </form>
      </Grid>

      </Grid>
    </React.Fragment>
  )
}

const rolesObject = {
  "SystemAdmin": 1000,
  "BackupAdmin": 1001,
  "AdmissionsManager": 2000,
  "AdmissionsOfficer": 2001,
  "AccountingManager": 3000,
  "AccountingOfficer": 3001,
}

const UserRolesView = (props) =>{
  const user = props.user;
  const [selectedRoles, setSelectedRoles] = React.useState(user.roles?user.roles:[]);
  const [changesMade, setChangesMade] = React.useState(false);

  const setUser = props.setUser;
  const saveUserRoles = props.saveUserRoles;

  const handleRoleToggle = (value) => () => {
    setChangesMade(true);

    const currentIndex = selectedRoles.indexOf(value);
    const newChecked = [...selectedRoles];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedRoles(newChecked);
  };

  const handleSaveUser = (e) =>{
    let userToSave = {...user};
    userToSave.roles = selectedRoles;
    saveUserRoles(userToSave);
    setChangesMade(false);
  }

  return(
    <React.Fragment>
      
      <Typography component="h2" variant="h6" color="primary" gutterBottom>Administrator Roles</Typography>
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    {rolesObject&&Object.entries(rolesObject).map(([role, value]) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <ListItem
            key={value}
            role={undefined} dense button onClick={handleRoleToggle(value)}
          >
           
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedRoles.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={role} />

          </ListItem>
        );
      })}
    </List>

    <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          {/* <Grid item>
            <Button onClick={(e) => reset()}>Cancel</Button>
          </Grid> */}
          <Grid item>
            <Button
              variant="contained"
              disabled={!changesMade}
              color="primary"
              onClick={(e)=>handleSaveUser(e)}
            >
              Save User Roles
            </Button>
          </Grid>
      </Grid>
      
    </React.Fragment>
  )
}

export default function UserView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [userId, setUserId] = React.useState(props._userid);
  const [selectedOption, setSelectedOption] = React.useState(1);
  const propsUser = props.user;
  const [user, setUser] = React.useState({});

  const [profileImageSrc, setProfileImageSrc] = React.useState();

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;

  const propsTermId = parseInt(props._termid); 
 
  const classes = useStyles();
   
  React.useEffect(()=>{
      setTimeout(()=>{
        if(error !== ''){
          setError('');
        }
      }, 3000);
  },[error]);
  
  React.useEffect(()=>{

    isMounted.current = true;
    
    const loadUser = () => {
      setLoading(true);
      ApiFunctions.getUser(propsUser, userId).then(res=>{
        console.log(res);
        setUser(res);
        setProfileImageSrc(getUserAvatarSrc(res._id));
        setLoading(false);
      }).catch(e=>{
          console.log(e);
      });
    }

    //load existing section
    if(userId)
      loadUser();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [userId, propsUpdateUser, propsUser]);

  const handleSelectedOption = (event, option) =>{
    setSelectedOption(option);
  }

  const saveUserRoles = (userToSave) =>{
    console.log(userToSave);
    let postedUser = {
      _id: userToSave._id,
      roles: userToSave.roles
    };
    ApiFunctions.saveUser(propsUser, postedUser).then(res=>{
      console.log(res);
      setUser(userToSave);
    });
  }

  const saveUserInfo = (userToSave) =>{
    console.log(userToSave);
    let postedUser = {
      _id: userToSave._id,
      realname: userToSave.realname,
      mobile: userToSave.mobile
    };
    ApiFunctions.saveUser(propsUser, postedUser).then(res=>{
      console.log(res);
      setUser(userToSave);
    });
  }

  const saveUserActive = (userToSave) => {
    console.log(userToSave);
    let postedUser = {
      _id: userToSave._id,
      active: userToSave.active
    };
    ApiFunctions.saveUser(propsUser, postedUser).then(res=>{
      console.log(res);
      setUser(userToSave);
    });
  }

  function getUserAvatarSrc(_userid){
    let randNum = Math.floor(Math.random()*1000) + 3;
    return ApiFunctions.getApiUri()+'/api/avatar/users/'+_userid+'/?token='+propsUser.token+'&rand='+randNum
  }

  return (
    
    <Container maxWidth="xl" className={classes.container}>
    <Box>
    {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
    </Box>
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
      {user &&
      <h2 style={{padding:0, margin:0}}> {user.realname?user.realname : <Skeleton style={{width:300,height:30}} animation="wave" />}</h2>
      }
      
      </Box>
    </Box>

    <Paper className={classes.paper}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={3} xl={2}>
        <Paper className={classes.paper}>
        <UserOptionsList selectedOption={selectedOption} handleSelectedOption={handleSelectedOption} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8} lg={9} xl={10}>
        <Paper className={classes.paper}>
        {selectedOption===1 &&user &&
          <UserInfoView 
            user={user}
            propsUser={propsUser}
            setUser={setUser}
            saveUserInfo={saveUserInfo}
            saveUserActive={saveUserActive}
            setError={setError}
            setProfileImageSrc={setProfileImageSrc}
            profileImageSrc={profileImageSrc}
            />
        }
        
        {selectedOption===2&&
          <UserRolesView 
            user={user}
            setUser={setUser}
            saveUserRoles={saveUserRoles}
          />
        }
        
        </Paper>
      </Grid>
      </Grid>
    
    </Paper>
    
    </Container>
  );
}