import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputBase,
  ListItemIcon,
  Checkbox,
  Typography,
  Grid,
  Button,
  Box,
  FormControl,
  TextField,
  Avatar,
  CircularProgress,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Chip,
  Select,
  MenuItem,
  IconButton,

} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import { Autocomplete } from '@material-ui/lab';

import axios from 'axios';
import edusim from '../../config/edusim';
import { ApiFunctions } from '../../services/Api';

import {Controller, useForm, useWatch} from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import maleAvatarPlaceholder from '../../imgs/maleAvatarPlaceholder.png';
import Resizer from 'react-image-file-resizer';
import { useTabContext } from '@material-ui/lab';
import { Close as CloseIcon, Add as AddIcon , People as PeopleIcon} from '@material-ui/icons';

import LevelEnrollmentsView from './Enrollments';
import LevelScheduleView from './Schedule';

const statusColors = {
  'Verified': "#32CD32",
  'Unverified': "#DC143C"
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    width: 300,
    minHeight:400,
    paddingBottom: theme.spacing(2)
  },
  mediaButton:{
    width: 300,
    padding:0, 
    border:0
  },
  media: {
    height: 300
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: '100%'
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const LevelOptionsList = ({selectedOption, handleSelectedOption}) =>{
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>Level Options</Typography>
      <List component="nav" className={classes.enrollmentsListRoot}>
        <ListItem
          button
          selected={selectedOption === 1}
          onClick={(event) => handleSelectedOption(event, 1)}
        >
          <ListItemText 
          primary={'Level Information'}
          secondary={''} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 2}
          onClick={(event) => handleSelectedOption(event, 2)}
        >
          <ListItemText 
          primary={'Grading Intervals'}
          secondary={''} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 3}
          onClick={(event) => handleSelectedOption(event, 3)}
        >
          <ListItemText 
          primary={'Student Groups'}
          secondary={''} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 4}
          onClick={(event) => handleSelectedOption(event, 4)}
        >
          <ListItemText 
          primary={'Student Enrollments'}
          secondary={''} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 5}
          onClick={(event) => handleSelectedOption(event, 5)}
        >
          <ListItemText 
          primary={'Level Subjects'}
          secondary={''} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 6}
          onClick={(event) => handleSelectedOption(event, 6)}
        >
          <ListItemText 
          primary={'Level Schedule'}
          secondary={''} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 7}
          onClick={(event) => handleSelectedOption(event, 7)}
        >
          <ListItemText 
          primary={'Level Rosters'}
          secondary={''} />
        </ListItem>
        
    </List>
    </React.Fragment>
  )
}

const LevelInfoView = (props) =>{
  const level = props.level;
  const propsUser = props.propsUser;
  const setError = props.setError;
  const saveLevelInfo = props.saveLevelInfo;
  const discontinueLevel = props.discontinueLevel;
  const continueLevel = props.continueLevel;
  const buttonLoading = props.buttonLoading;


  const [changesMade, setChangesMade] = React.useState(false);
  const monthdays = ["12-31","11-30","10-31","09-30","08-31","07-31","06-30","05-31","04-30","03-31","02-28","01-31"];
  const classes = useStyles();
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Level Name is Required").min(4),
    /* telephone: Yup.string().notRequired().matches(phoneRegex,{ excludeEmptyString: true, message:"Must be a valid international mobile number like +97377887799"}), */
    translated_name: Yup.string(),
    min_age: Yup.number().min(0).max(18).typeError('Must be a number').required("Must be a number"),
    max_age: Yup.number().min(0).max(19).typeError('Must be a number').moreThan(Yup.ref('min_age'), "Max age should be > min age"),
    age_ref_monthday: Yup.string().required("Must be a valid month day like 12-31").test('Valid Month Day.', 'Must be a valid month day like 12-31', 
      function(value) {
          return monthdays.includes(value);
      }),
    max_capacity: Yup.number().min(1).max(500),
  });
  const {
    handleSubmit,
    reset,
    errors: fieldsErrors,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {name:'', translated_name:'', min_age:0, max_age:0, age_ref_monthday: '12-31',max_capacity:1},
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data){
    console.log(data);
    let levelToSave = {...level, ...data};
    saveLevelInfo(levelToSave);
  }

  React.useEffect(()=>{
    setValue("name", (level.name || '')) ;
    setValue("translated_name", (level.translated_name || ''));
    setValue("min_age", (level.min_age || ''));
    setValue("max_age", (level.max_age || ''));
    setValue("age_ref_monthday", (level.age_ref_monthday || ''));
    setValue("max_capacity", (level.max_capacity || ''));
  },[level]);

  const namewatch = useWatch({
    control,
    name: 'name'
  });

  const transwatch = useWatch({
    control,
    name: 'translated_name'
  });

  const minagewatch = useWatch({
    control,
    name: 'min_age'
  });

  const maxagewatch = useWatch({
    control,
    name: 'max_age'
  });

  const monthdayrefwatch = useWatch({
    control,
    name: 'age_ref_monthday'
  });

  const maxcapacitywatch = useWatch({
    control,
    name: 'max_capacity'
  })

  React.useEffect(()=>{
    namewatch!==level.name?setChangesMade(true):setChangesMade(false);
  },[namewatch, level]);

  React.useEffect(()=>{
    transwatch!==level.translated_name?setChangesMade(true):setChangesMade(false);
  },[transwatch, level]);

  React.useEffect(()=>{
    minagewatch!==level.min_age?setChangesMade(true):setChangesMade(false);
  },[minagewatch, level]);

  React.useEffect(()=>{
    maxagewatch!==level.max_age?setChangesMade(true):setChangesMade(false);
  },[maxagewatch, level]);

  React.useEffect(()=>{
    monthdayrefwatch!==level.age_ref_monthday?setChangesMade(true):setChangesMade(false);
  },[monthdayrefwatch, level]);

  React.useEffect(()=>{
    maxcapacitywatch!==level.max_capacity?setChangesMade(true):setChangesMade(false);
  },[maxcapacitywatch, level]);

  return(
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <h3>{level&&level._schoolid&&(level._schoolid.name+" / ")}{level&&level.structures&&level.structures.map((struct, index)=>(<span key={index}>{struct.name}{((index+1)!==level.structures.length)&&' / '}</span>))}</h3>
        </Grid>
      </Grid>
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>Level Information</Typography>
      </Grid>
      <Grid item>
        {level.discontinued?
        <Button color="primary" onClick={(e)=>continueLevel({...level})}>Continue Level</Button>
        :
        <Button color="secondary" onClick={(e)=>discontinueLevel({...level})}>Discontinue Level</Button>
        }
        
      </Grid>
    </Grid>
   
    
    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>

     <Grid container spacing={3}>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="name"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="name"
               label="Level Name"
               variant="outlined"
               fullWidth
               error={fieldsErrors.name ? true : false}
               helperText={
                 fieldsErrors.name ? fieldsErrors.name.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="translated_name"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="translated_name"
               label="Translated Name"
               variant="outlined"
               fullWidth
               error={fieldsErrors.translated_name ? true : false}
               helperText={
                 fieldsErrors.translated_name ? fieldsErrors.translated_name.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="min_age"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="min_age"
               label="Minimum Age"
               variant="outlined"
               fullWidth
               error={fieldsErrors.min_age ? true : false}
               helperText={
                 fieldsErrors.min_age ? fieldsErrors.min_age.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="max_age"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="max_age"
               label="Maximum Age"
               variant="outlined"
               fullWidth
               error={fieldsErrors.max_age ? true : false}
               helperText={
                 fieldsErrors.max_age ? fieldsErrors.max_age.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="age_ref_monthday"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="age_ref_monthday"
               label="Reference Month Day"
               variant="outlined"
               fullWidth
               error={fieldsErrors.age_ref_monthday ? true : false}
               helperText={
                 fieldsErrors.age_ref_monthday ? fieldsErrors.age_ref_monthday.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="max_capacity"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="max_capacity"
               label="Maximum Capacity"
               variant="outlined"
               fullWidth
               error={fieldsErrors.max_capacity ? true : false}
               helperText={
                 fieldsErrors.max_capacity ? fieldsErrors.max_capacity.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     

     </Grid>

     <Grid
         container
         spacing={3}
         style={{ marginTop: 10 }}
         direction="row"
         justify="flex-end"
         alignItems="center"
       >
         <Grid item>
           {buttonLoading?
            <CircularProgress />
            :
            <Button
              variant="contained"
              disabled={!changesMade}
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Save Level
            </Button>
          }
           
         </Grid>
     </Grid>

     </form>
     
    </React.Fragment>
  )
}

const LevelIntervalsView = (props) =>{

  const level = props.level;
  const yearsObject = props.yearsObject;
  const propsUser = props.propsUser;
  const setError = props.setError;
  const isMounted = React.useRef(null);
  const [intervals, setIntervals] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState(2021);
  const [loading, setLoading] = React.useState(false);

  const handleAddIntervalClick = (e, _termid) =>{
    let newInterval = {
      name: "New Interval",
      short_name: "NI",
      _termid: _termid,
    };
    setLoading(true);
    ApiFunctions.saveLevelInterval(propsUser, level._id, newInterval).then((res)=>{
      newInterval._id = res;
      let tempIntervals = [...intervals];
      tempIntervals.push(newInterval);
      setIntervals(tempIntervals);
    }).catch((e)=>{
      setError("Cannot add interval. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
  }

  const handleRemoveIntervalClick = (e, intervalToRemove)=>{
    removeInterval(intervalToRemove);
  }

  function removeInterval(intervalToRemove){
    setLoading(true);
    ApiFunctions.removeLevelInterval(propsUser, level._id, intervalToRemove).then((res)=>{
        let tempIntervals = [...intervals];
        let remainingIntervals = tempIntervals.filter(s=>s._id!==intervalToRemove._id);
        setIntervals(remainingIntervals);
    }).catch((e)=>{
      setError("Cannot remove interval. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
  }
  
  const handleYearChange = (e) =>{
    let year = e.target.value;
    setSelectedYear(year);
  } 

  React.useEffect(()=>{

    isMounted.current = true;
    
    const loadLevelIntervals = () => {
      setLoading(true);
      ApiFunctions.getLevelIntervals(propsUser, level._id).then(res=>{
        console.log(res);
        setIntervals(res);
      }).catch(e=>{
          setError("Cannot get level intervals. Please try again later.");
      }).finally(()=>{
        setLoading(false);
      });
    }

    //load existing school
    if(level&&level._id)
      loadLevelIntervals();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [level, propsUser]);

  const handleIntervalNameBlur = (e, short) =>{
    let newValue = e.target.value;
    let intervalid = e.target.getAttribute("data-intervalid");
    if(newValue!==e.target.getAttribute("data-init")){
    if(newValue.length < 5 && !short){
      e.target.value = e.target.getAttribute("data-init");
      return;
    }
    const existingIntervalIndex = intervals.findIndex(i=>i._id===intervalid);
    let tempInt = {...intervals[existingIntervalIndex]};
    short?(tempInt.short_name=newValue):(tempInt.name = newValue);
    setLoading(true);
    console.log(tempInt);
    ApiFunctions.saveLevelInterval(propsUser, level._id, tempInt).then((res)=>{
      let tempIntervals = [...intervals];
      tempIntervals[existingIntervalIndex] = tempInt;
      setIntervals(tempIntervals);
    }).catch((e)=>{
      setError("Cannot modify interval name. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
    }
  }

  const handleIntervalNameFocus = (e) =>{
    e.target.setAttribute("data-init", e.target.value);
  }

  function getGradingIntervalsInTerm(_termid){
    const gisInTerm = intervals.filter((gi)=>gi._termid===_termid);
    return gisInTerm;
  }

  return (
    <React.Fragment>
    <Grid 
    container 
    spacing={3}
    direction="row"
    justify="space-between"
    alignItems="center">
      <Grid item>
        <Typography component="h2" variant="h6" color="primary">Level Intervals</Typography>
      </Grid>
      
      <Grid item>
          <Select
          variant='outlined'
          defaultValue={2021}
          onChange={(e) => handleYearChange(e)}>
          {yearsObject&&Object.keys(yearsObject).map((key, index)=>(
              <MenuItem key={index} value={key}>
                  {key}
              </MenuItem>
          ))}
          </Select>
      </Grid>
    </Grid>
    <Grid 
    container 
    spacing={3}
    direction="column"
    justify="flex-start"
    alignItems="baseline">

   {yearsObject[selectedYear]?.map((term, kt)=>{
      return(
      <Grid item style={{width:"100%"}} key={kt}>
        <Grid 
          container 
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item><Typography component="h2" variant="h6">{term.name}</Typography></Grid>
          <Grid item>
            <IconButton
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={(e)=>handleAddIntervalClick(e, term._id)}>
                <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      
        <List style={{width:"100%", border:"1px solid #cccccc"}}>
        {getGradingIntervalsInTerm(term._id).map((interval, kgi)=>(
     
        <ListItem style={{width:"100%"}} key={kgi}>
            <ListItemText id={'id'} primary={
              <InputBase
                fullWidth
                defaultValue={interval.name}
                inputProps={{ 
                  'aria-label': 'naked',
                  'data-intervalid': interval._id
                }}
                onBlur={(e)=>handleIntervalNameBlur(e)}
                onFocus={(e)=>handleIntervalNameFocus(e)}
              />
              } 
              secondary={
                <InputBase
                  defaultValue={interval.short_name}
                  inputProps={{ 
                    'aria-label': 'naked',
                    'data-intervalid': interval._id
                  }}
                  onBlur={(e)=>handleIntervalNameBlur(e, true)}
                  onFocus={(e)=>handleIntervalNameFocus(e, true)}
                />
                }/>
            <ListItemSecondaryAction>
              <IconButton
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={(e)=>handleRemoveIntervalClick(e, interval)}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
       ))}
        </List>
        </Grid>
      )
    })}
    </Grid>
    
    </React.Fragment>

  )
}

const LevelGroupsView = (props) =>{
  const level = props.level;
  const propsUser = props.propsUser;
  const setError = props.setError;
  const isMounted = React.useRef(null);
  const [studentGroups, setStudentGroups] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(()=>{

    isMounted.current = true;
    
    const loadStudentGroups = () => {
      setLoading(true);
      ApiFunctions.getStudentGroups(propsUser, level._id).then(res=>{
        console.log(res);
        setStudentGroups(res);
      }).catch(e=>{
          setError("Cannot get level groups. Please try again later.");
      }).finally(()=>{
        setLoading(false);
      });
    }

    //load existing school
    if(level&&level._id)
      loadStudentGroups();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [level, propsUser]);

  const handleAddGroupClick = (e) =>{
    let newGroup = {
      name: "New Group",
      short_name: "NG",
      _levelid: level._id,

    };
    setLoading(true);
    ApiFunctions.saveStudentGroup(propsUser, level._id, newGroup).then((res)=>{
      newGroup._id = res;
      let tempGroups = [...studentGroups];
      tempGroups.push(newGroup);
      setStudentGroups(tempGroups);
    }).catch((e)=>{
      setError("Cannot add group. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
  }

  const handleDiscontinueClick = (e) =>{
  }

  const handleGroupNameBlur = (e, short) =>{
    let newValue = e.target.value;
    let groupid = e.target.getAttribute("data-groupid");
    if(newValue!==e.target.getAttribute("data-init")){
    if(newValue.length < 5 && !short){
      e.target.value = e.target.getAttribute("data-init");
      return;
    }
    const existingGroupIndex = studentGroups.findIndex(i=>i._id===groupid);
    let tempGroup = {...studentGroups[existingGroupIndex]};
    short?(tempGroup.short_name=newValue):(tempGroup.name = newValue);
    setLoading(true);
    console.log(tempGroup);
    ApiFunctions.saveStudentGroup(propsUser, level._id, tempGroup).then((res)=>{
      let tempGroups = [...studentGroups];
      tempGroups[existingGroupIndex] = tempGroup;
      setStudentGroups(tempGroups);
    }).catch((e)=>{
      setError("Cannot modify group name. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
    }
  }

  const handleGroupNameFocus = (e) =>{
    e.target.setAttribute("data-init", e.target.value);
  }

  return (
    <React.Fragment>
    <Grid 
    container 
    spacing={3}
    direction="row"
    justify="flex-start"
    alignItems="center">
      <Grid item>
        <Typography component="h2" variant="h6" color="primary">Student Groups</Typography>
      </Grid>
      
      <Grid item>
        <IconButton
          variant="contained"
          disabled={loading}
          color="primary"
          onClick={(e)=>handleAddGroupClick(e)}>
            <AddIcon />
        </IconButton>
      </Grid>
    </Grid>


    <Grid 
    container 
    spacing={3}
    direction="row"
    justify="flex-start"
    alignItems="baseline">
        {studentGroups&&studentGroups.map((group, key)=>(
        <Grid item xs={6} key={key}>
        <List style={{width:"100%", border:"1px solid #cccccc"}}>
        <ListItem style={{width:"100%"}} >
            <ListItemText id={'id'} primary={
              <InputBase
                fullWidth
                defaultValue={group.name}
                inputProps={{ 
                  'aria-label': 'naked',
                  'data-groupid': group._id
                }}
                onBlur={(e)=>handleGroupNameBlur(e)}
                onFocus={(e)=>handleGroupNameFocus(e)}
              />
              } 
              secondary={
                <InputBase
                  defaultValue={group.short_name}
                  inputProps={{ 
                    'aria-label': 'naked',
                    'data-groupid': group._id
                  }}
                  onBlur={(e)=>handleGroupNameBlur(e, true)}
                  onFocus={(e)=>handleGroupNameFocus(e, true)}
                />
                }/>
            <ListItemSecondaryAction>
              <IconButton
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={(e)=>handleDiscontinueClick(e, group)}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        </List>
        </Grid>
       ))}
    </Grid>
    
    </React.Fragment>

  )

}

const LevelSubjectsView = (props) =>{
  const level = props.level;
  const propsUser = props.propsUser;
  const setError = props.setError;
  const isMounted = React.useRef(null);
  const [subjects, setSubjects] = React.useState([]);
  const [selectedSubject, setSelectedSubject] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const SubjectInstructorsView = (props) =>{
    const {subject, setSelectedSubject, removeInstructor, addInstructor} = props;

    const [newInstructor, setNewInstructor] = React.useState(null);

    const handleAutoComplete = (e, newValue) =>{
      console.log(newValue);

      setNewInstructor(newValue);
    }

    const handleAddInstructorClick = (e)=>{
      addInstructor(subject, newInstructor);
    }

    const handleRemoveInstructorClick = (e, instructor)=>{
      removeInstructor(subject, instructor);
    }

    const getInstructor = (_instructorid) =>{
      const instructorIndex = level.instructors.findIndex((i)=>i._id===_instructorid);
      return instructorIndex>-1?level.instructors[instructorIndex]:null;
    }

    return (
      <React.Fragment>
        <Grid 
        container 
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="center">
          <Grid item>
            <IconButton
              variant="contained"
              color="primary"
              onClick={()=>setSelectedSubject(null)}>
                <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item>
          <Typography component="h2" variant="h6" color="primary">{subject&&subject.name} Instructors</Typography>
        </Grid>
        
        <Grid item style={{width: 200}}>

        <Autocomplete
          options={level.instructors}
          autoHighlight
          getOptionLabel={(option) => option.name? option.name:''}
          renderOption={(option) => (
            <React.Fragment>
              {option.name} ({option.nickname})
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Instructor"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              fullWidth   
            />
          )}
          getOptionSelected={(option, value) => value._id === option._id}
          /* value={application.guardians[0]._id.nationality? application.guardians[0]._id.nationality : null} */
          onChange={(e,newValue)=>handleAutoComplete(e, newValue)} 
        />
          
        </Grid>

        <Grid item>
        <IconButton
            variant="contained"
            disabled={newInstructor?false:true}
            color="primary"
            onClick={(e)=>handleAddInstructorClick(e)}>
              <AddIcon />
          </IconButton>
          
          </Grid>

        </Grid>
     
      <Grid 
        container 
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="baseline">
      {subject.instructors&&subject.instructors.map((instructor, key)=>(
        <Grid item xs={6} key={key}>
        <List style={{width:"100%", border:"1px solid #cccccc"}}>
        <ListItem style={{width:"100%"}} >
            <ListItemText primary={instructor&&getInstructor(instructor._instructorid).name} />
            <ListItemSecondaryAction>
              <IconButton
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={(e)=>handleRemoveInstructorClick(e, getInstructor(instructor._instructorid))}>
                <CloseIcon />
              </IconButton>
              </ListItemSecondaryAction>
          </ListItem>
          </List>
        </Grid>
      ))}
      </Grid>
      </React.Fragment>
    )
  }

  React.useEffect(()=>{

    isMounted.current = true;
    
    const loadLevelSubjects = () => {
      setLoading(true);
      ApiFunctions.getLevelSubjects(propsUser, level._id).then(res=>{
        console.log(res);
        setSubjects(res);
      }).catch(e=>{
          setError("Cannot get level subjects. Please try again later.");
      }).finally(()=>{
        setLoading(false);
      });
    }

    //load existing school
    if(level&&level._id)
      loadLevelSubjects();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [level, propsUser]);

  const handleAddSubjectClick = (e) =>{
    let newSubject = {
      name: "New Subject",
      short_name: "NS",
      _levelid: level._id,

    };
    setLoading(true);
    ApiFunctions.saveLevelSubject(propsUser, level._id, newSubject).then((res)=>{
      newSubject._id = res;
      let tempSubjects = [...subjects];
      tempSubjects.push(newSubject);
      setSubjects(tempSubjects);
    }).catch((e)=>{
      setError("Cannot add subject. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
  }

  const handleSubjectNameBlur = (e, short) =>{
    const newValue = e.target.value;
    const subjectid = e.target.getAttribute("data-subjectid");
    if(newValue!==e.target.getAttribute("data-init")){
    if(newValue.length < 5 && !short){
      e.target.value = e.target.getAttribute("data-init");
      return;
    }
    const existingSubjectIndex = subjects.findIndex(i=>i._id===subjectid);
    let tempSubject = {...subjects[existingSubjectIndex]};
    short?(tempSubject.short_name=newValue):(tempSubject.name = newValue);
    setLoading(true);
    console.log(tempSubject);
    ApiFunctions.saveLevelSubject(propsUser, level._id, tempSubject).then((res)=>{
      let tempSubjects = [...subjects];
      tempSubjects[existingSubjectIndex] = tempSubject;
      setSubjects(tempSubjects);
    }).catch((e)=>{
      setError("Cannot modify subject name. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
    }
  }

  const handleSubjectNameFocus = (e) =>{
    e.target.setAttribute("data-init", e.target.value);
  }

  const handleInstructorsClick = (e, subject) =>{
    setSelectedSubject(subject);
  }

  function removeInstructor (subject, instructor){
    ApiFunctions.removeSubjectInstructor(propsUser, level._id, subject._id, instructor._id).then((res)=>{
      console.log(res);
      const existingSubjectIndex = subjects.findIndex(s=>s._id===subject._id); 
      let currentInstructors = [...subject.instructors.filter((i)=>i._instructorid!==instructor._id)];
      let tempSubjects = [...subjects];
      tempSubjects[existingSubjectIndex].instructors = currentInstructors;
      setSubjects(tempSubjects);
    });
  }

  function addInstructor (subject, instructor){
    ApiFunctions.addSubjectInstructor(propsUser, level._id, subject._id, instructor._id).then((res)=>{
      console.log(res);
      const existingSubjectIndex = subjects.findIndex(s=>s._id===subject._id); 
      let currentInstructors = subject.instructors?[...subject.instructors]:[];
      currentInstructors.push({_instructorid: instructor._id});
      let tempSubjects = [...subjects];
      tempSubjects[existingSubjectIndex].instructors = currentInstructors;
      setSubjects(tempSubjects);
    });
  }

  return (
    selectedSubject? <SubjectInstructorsView removeInstructor={removeInstructor} addInstructor={addInstructor} subject={selectedSubject} setSelectedSubject={setSelectedSubject} /> :
    <React.Fragment>
    <Grid 
    container 
    spacing={3}
    direction="row"
    justify="flex-start"
    alignItems="center">
      <Grid item>
        <Typography component="h2" variant="h6" color="primary">Level Subjects</Typography>
      </Grid>
      
      <Grid item>
        <IconButton
          variant="contained"
          disabled={loading}
          color="primary"
          onClick={(e)=>handleAddSubjectClick(e)}>
            <AddIcon />
        </IconButton>
      </Grid>
    </Grid>


    <Grid 
    container 
    spacing={3}
    direction="row"
    justify="flex-start"
    alignItems="baseline">
        {subjects&&subjects.map((subject, key)=>(
        <Grid item xs={6} key={key}>
        <List style={{width:"100%", border:"1px solid #cccccc"}}>
        <ListItem style={{width:"100%"}} >
            <ListItemText id={'id'} primary={
              <InputBase
                fullWidth
                defaultValue={subject.name}
                inputProps={{ 
                  'aria-label': 'naked',
                  'data-subjectid': subject._id
                }}
                onBlur={(e)=>handleSubjectNameBlur(e)}
                onFocus={(e)=>handleSubjectNameFocus(e)}
              />
              } 
              secondary={
                <InputBase
                  defaultValue={subject.short_name}
                  inputProps={{ 
                    'aria-label': 'naked',
                    'data-subjectid': subject._id
                  }}
                  onBlur={(e)=>handleSubjectNameBlur(e, true)}
                  onFocus={(e)=>handleSubjectNameFocus(e, true)}
                />
                }/>
            <ListItemSecondaryAction>
             {/*  <IconButton
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={(e)=>handleDiscontinueClick(e, group)}>
                <CloseIcon />
              </IconButton> */}
              <IconButton
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={(e)=>handleInstructorsClick(e, subject)}>
                <PeopleIcon />
              </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        </List>
        </Grid>
       ))}
    </Grid>
    
    </React.Fragment>

  )

}

export default function LevelView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const levelId = props._levelid;
  const [selectedOption, setSelectedOption] = React.useState(1);
  const propsUser = props.user;
  const [level, setLevel] = React.useState({});
  const [terms, setTerms] = React.useState([]);
  const [yearsObject, setYearsObject] = React.useState({});
  const [authorized, setAuthorized] = React.useState(true);
  const [optionsListVisible, setOptionsListVisible] = React.useState(true);
  

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;

  const classes = useStyles();

  function getYearsObject(terms){
    let yearsObj = {};
    terms.map((term)=>{
      yearsObj[term.year]?yearsObj[term.year].push(term):yearsObj[term.year]=[term];
    });
    console.log(yearsObj);
    return yearsObj;
  }
   
  React.useEffect(()=>{
      setTimeout(()=>{
        if(error !== ''){
          setError('');
        }
      }, 3000);
  },[error]);
  
  React.useEffect(()=>{
    isMounted.current = true;

    const loadLevel = () => {
      setLoading(true);
      ApiFunctions.getLevel(propsUser, levelId).then(level=>{
        console.log(level);
        setLevel(level);
        level._schoolid&&ApiFunctions.getTermsInSchool(propsUser, level._schoolid._id).then((terms)=>{
          setTerms(terms);
          setYearsObject(getYearsObject(terms));
        });
      }).catch(e=>{
        handleErrors(e);
      }).finally(()=>{
        isMounted.current&&setLoading(false);
      });
    }

    const handleErrors = (e) =>{
      console.log(e);
      if(e.response&&e.response.status === 401){
        setAuthorized(false);
      }else if(e.response&&e.response.status === 403){
        setTimeout(()=>propsUpdateUser({}),2000);
      }else if(e.response&&e.response.status !== 200){
        setError("There seems to be a problem with the service at the momemt. Please try again later.");
      }else{
        setError("Something went wrong while reaching the API service. Are you connected to the internet?");
      }
    }

    //load existing school
    if(levelId)
      loadLevel();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [levelId, propsUpdateUser, propsUser]);

  const handleSelectedOption = (event, option) =>{
    setSelectedOption(option);
  }

  const saveLevelInfo = (levelToSave) =>{
    console.log(levelToSave);
    setButtonLoading(true);
    ApiFunctions.saveSchoolLevel(propsUser, levelToSave).then(res=>{
      console.log(res);
      setLevel(levelToSave);
    }).catch(e=>{
      setError("Something went wrong. Try again later.");
    }).finally(()=>{
      setButtonLoading(false);
    });
  }

  const discontinueLevel = (levelToSave) =>{
    console.log('discontinuing level ' + level.name);
    levelToSave.discontinued = true;
    saveLevelInfo(levelToSave);    
  }

  const continueLevel = (levelToSave) =>{
    console.log('continuing level ' + level.name);
    levelToSave.discontinued = false;
    saveLevelInfo(levelToSave);    
  }
  return (
    
    <Container /* maxWidth="xl" */ className={classes.container}>
    <Box>
    {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
    </Box>
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
      {level &&
      <h2 style={{padding:0, margin:0}}> {level.name?level.name : <Skeleton style={{width:300,height:30}} animation="wave" />}</h2>
      }
      
      </Box>
    </Box>

    <Paper className={classes.paper}>
    <Grid container wrap='nowrap' spacing={3}>
      
      <Grid item xs={3} style={{ minWidth: "250px", maxWidth: "250px"}} /* xs={12} md={3} lg={3} xl={2} */>
        <Paper className={classes.paper}>
          <LevelOptionsList selectedOption={selectedOption} handleSelectedOption={handleSelectedOption} />
        </Paper>
      </Grid>
      <Grid item style={{ flexGrow: "1"}} /* xs={12} md={9} lg={9} xl={10} */>
        <Paper className={classes.paper}>
        {selectedOption===1 &&level &&
          <LevelInfoView 
            level={level}
            propsUser={propsUser}
            setLevel={setLevel}
            saveLevelInfo={saveLevelInfo}
            discontinueLevel={discontinueLevel}
            continueLevel={continueLevel}
            setError={setError}
            buttonLoading={buttonLoading}
            />
        }
        
        {selectedOption===2&&
          <LevelIntervalsView 
            level={level}
            yearsObject={yearsObject}
            propsUser={propsUser}
            setError={setError}
          />
        }

        {selectedOption===3&&
          <LevelGroupsView 
            level={level}
            propsUser={propsUser}
            setError={setError}
          />
        }

        {selectedOption===4&&
          <LevelEnrollmentsView 
            level={level}
            yearsObject={yearsObject}
            propsUser={propsUser}
            setError={setError}
          />
        }

        {selectedOption===5&&
          <LevelSubjectsView 
            level={level}
            propsUser={propsUser}
            setError={setError}
          />
        }

        {selectedOption===6&&
          <LevelScheduleView 
            level={level}
            setLevel={setLevel}
            yearsObject={yearsObject}
            propsUser={propsUser}
            setError={setError}
          />
        }
        </Paper>
      </Grid>
      </Grid>
    
    </Paper>
    
    </Container>
  );
}