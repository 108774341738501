import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputBase,
  ListItemIcon,
  Checkbox,
  Typography,
  Grid,
  Button,
  Box,
  FormControl,
  TextField,
  Avatar,
  CircularProgress,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Chip,
  IconButton
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import axios from 'axios';
import edusim from '../../config/edusim';
import { ApiFunctions } from '../../services/Api';

import {Controller, useForm, useWatch} from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import maleAvatarPlaceholder from '../../imgs/maleAvatarPlaceholder.png';
import Resizer from 'react-image-file-resizer';
import { useTabContext } from '@material-ui/lab';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';

const statusColors = {
  'Verified': "#32CD32",
  'Unverified': "#DC143C"
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  cardRoot: {
    width: 300,
    minHeight:400,
    paddingBottom: theme.spacing(2)
  },
  mediaButton:{
    width: 300,
    padding:0, 
    border:0
  },
  media: {
    height: 300
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const SchoolOptionsList = ({selectedOption, handleSelectedOption}) =>{
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>School Options</Typography>
      <List component="nav" className={classes.enrollmentsListRoot}>
        <ListItem
          button
          selected={selectedOption === 1}
          onClick={(event) => handleSelectedOption(event, 1)}
        >
          <ListItemText 
          primary={'School Information'}
          secondary={''} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 2}
          onClick={(event) => handleSelectedOption(event, 2)}
        >
          <ListItemText 
          primary={'School Structures'}
          secondary={''} />
        </ListItem>
        
    </List>
    </React.Fragment>
  )
}

const SchoolInfoView = (props) =>{
  const school = props.school;
  const propsUser = props.propsUser;
  const setError = props.setError;
  const saveSchoolInfo = props.saveSchoolInfo;
  const buttonLoading = props.buttonLoading;

  const [changesMade, setChangesMade] = React.useState(false);

  const classes = useStyles();
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("School name is required").min(5),
    telephone: Yup.string().notRequired().matches(phoneRegex,{ excludeEmptyString: true, message:"Must be a valid international mobile number like +97377887799"}),
    translated_name: Yup.string(),
    address: Yup.string(),
    license_number: Yup.string()
  });
  const {
    handleSubmit,
    reset,
    errors: fieldsErrors,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {name:'', translated_name:'', address:'', telephone:'', license_number:''},
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data){
    console.log(data);
    let schoolToSave = {...school, ...data};
    saveSchoolInfo(schoolToSave);
  }

  React.useEffect(()=>{
    setValue("name", (school.name || '')) ;
    setValue("translated_name", (school.translated_name || ''));
    setValue("address", (school.address || ''));
    setValue("telephone", (school.telephone || ''));
    setValue("license_number", (school.license_number || ''));
  },[school]);

  const namewatch = useWatch({
    control,
    name: 'name'
  });

  const addresswatch = useWatch({
    control,
    name: 'address'
  });

  const transwatch = useWatch({
    control,
    name: 'translated_name'
  });

  const telwatch = useWatch({
    control,
    name: 'telephone'
  });

  const licwatch = useWatch({
    control,
    name: 'license_number'
  });

  React.useEffect(()=>{
    namewatch!==school.name?setChangesMade(true):setChangesMade(false);
  },[namewatch, school]);

  React.useEffect(()=>{
    addresswatch!==school.address?setChangesMade(true):setChangesMade(false);
  },[addresswatch, school]);

  React.useEffect(()=>{
    transwatch!==school.translated_name?setChangesMade(true):setChangesMade(false);
  },[transwatch, school]);

  React.useEffect(()=>{
    telwatch!==school.telephone?setChangesMade(true):setChangesMade(false);
  },[telwatch, school]);

  React.useEffect(()=>{
    licwatch!==school.license_number?setChangesMade(true):setChangesMade(false);
  },[licwatch, school]);


  return(
    <React.Fragment>

    <Typography component="h2" variant="h6" color="primary" gutterBottom>School Information</Typography>
    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>

     <Grid container spacing={3}>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="name"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="name"
               label="School Name"
               variant="outlined"
               fullWidth
               error={fieldsErrors.name ? true : false}
               helperText={
                 fieldsErrors.name ? fieldsErrors.name.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="translated_name"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="translated_name"
               label="Translated Name"
               variant="outlined"
               fullWidth
               error={fieldsErrors.translated_name ? true : false}
               helperText={
                 fieldsErrors.translated_name ? fieldsErrors.translated_name.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="address"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="address"
               label="Address"
               variant="outlined"
               fullWidth
               error={fieldsErrors.address ? true : false}
               helperText={
                 fieldsErrors.address ? fieldsErrors.address.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="telephone"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="telephone"
               label="Telephone"
               variant="outlined"
               fullWidth
               error={fieldsErrors.telephone ? true : false}
               helperText={
                 fieldsErrors.telephone ? fieldsErrors.telephone.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     <Grid item xs={6}>
       <FormControl
         fullWidth
         className={classes.margin}
         variant="outlined"
       >
         <Controller
           name="license_number"
           control={control}
           defaultValue=""
           as={
             <TextField
               name="license_number"
               label="License Number"
               variant="outlined"
               fullWidth
               error={fieldsErrors.license_number ? true : false}
               helperText={
                 fieldsErrors.license_number ? fieldsErrors.license_number.message : null
               }
             ></TextField>
           }
         ></Controller>
       </FormControl>
     </Grid>

     </Grid>

     <Grid
         container
         spacing={3}
         style={{ marginTop: 10 }}
         direction="row"
         justify="flex-end"
         alignItems="center"
       >
         <Grid item>
           {buttonLoading?
            <CircularProgress />
            :
            <Button
              variant="contained"
              disabled={!changesMade}
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Save School
            </Button>
          }
           
         </Grid>
     </Grid>

     </form>
     
    </React.Fragment>
  )
}

const SchoolStructuresView = (props) =>{

  const school = props.school;
  const propsUser = props.propsUser;
  const setError = props.setError;
  const isMounted = React.useRef(null);
  const [structures, setStructures] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleAddRootClick = (e) =>{
    let newStruct = {
      name: "New Root Structure",
      _parentstructid: null,
      _schoolid: school._id
    };
    setLoading(true);
    ApiFunctions.saveSchoolStructure(propsUser, school._id, newStruct).then((res)=>{
      newStruct._id = res;
      let tempStructs = [...structures];
      tempStructs.push(newStruct);
      setStructures(tempStructs);
    }).catch((e)=>{
      setError("Cannot add a new root structure. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
  }

  const handleAddChildClick = (e, struct) =>{
    let newStruct = {
      name: "New Child Structure",
      _parentstructid: struct._id,
      _schoolid: school._id
    }
    setLoading(true);
    ApiFunctions.saveSchoolStructure(propsUser, school._id, newStruct).then((res)=>{
      newStruct._id = res;
      let tempStructs = [...structures];
      tempStructs.push(newStruct);
      setStructures(tempStructs);
    }).catch((e)=>{
      setError("Cannot add a new child structure. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
  }

  function removeStruct(structIdsToRemove){
    setLoading(true);
    ApiFunctions.removeSchoolStructure(propsUser, school._id, structIdsToRemove).then((res)=>{
      structIdsToRemove.forEach((structId)=>{
        let tempStructs = [...structures];
        let remainingStructs = tempStructs.filter(s=>s._id!==structId);
        setStructures(remainingStructs);
      });
    }).catch((e)=>{
      setError("Cannot remove structure. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
  }

  const handleRemoveStructClick = (e, struct) =>{
    let structIdsToRemove = [];
    let structChildren = getChildren(struct);
    structChildren.forEach((child)=>{
      let structSubChildren = getChildren(child);
      structSubChildren.forEach((subchild)=>{
        structIdsToRemove.push(subchild._id);
      });
      structIdsToRemove.push(child._id);
    });
    structIdsToRemove.push(struct._id);
    removeStruct(structIdsToRemove);
  }
  
  function getRootStructures(){
    let rootStructs = structures.filter(s=>s._parentstructid===null);
    return rootStructs;
  }
  
  function getChildren(structure){
    let childStructs = structures.filter(s=>s._parentstructid===structure._id);
    return childStructs;
  }

  React.useEffect(()=>{

    isMounted.current = true;
    
    const loadSchoolStructures = () => {
      setLoading(true);
      ApiFunctions.getSchoolStructures(propsUser, school._id).then(res=>{
        console.log(res);
        setStructures(res);
      }).catch(e=>{
          setError("Cannot get school structs. Please try again later.");
      }).finally(()=>{
        setLoading(false);
      });
    }

    //load existing school
    if(school&&school._id)
      loadSchoolStructures();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [school, propsUser]);

  const handleStructNameBlur = (e) =>{
    let newValue = e.target.value;
    let structid = e.target.getAttribute("data-structid");
    if(newValue!==e.target.getAttribute("data-init")){
    if(newValue.length < 5){
      e.target.value = e.target.getAttribute("data-init");
      return;
    }
    const existingStructIndex = structures.findIndex(b=>b._id===structid);
    let tempStruct = {...structures[existingStructIndex]};
    tempStruct.name = newValue;
    setLoading(true);
    console.log(tempStruct);
    ApiFunctions.saveSchoolStructure(propsUser, school._id, tempStruct).then((res)=>{
      let tempStructs = [...structures];
      tempStructs[existingStructIndex] = tempStruct;
      setStructures(tempStructs);
    }).catch((e)=>{
      setError("Cannot modify structure name. Please try again later.");
    }).finally(()=>{
      setLoading(false);
    });
    }
  }

  const handleStructNameFocus = (e) =>{
    e.target.setAttribute("data-init", e.target.value);
  }
  return (
    <React.Fragment>
    <Grid 
    container 
    spacing={3}
    direction="row"
    justify="flex-start"
    alignItems="center">
      <Grid item>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>School Structures</Typography>
      </Grid>
      <Grid item>
      <IconButton
        variant="contained"
        disabled={loading}
        color="primary"
        onClick={(e)=>handleAddRootClick(e)}>
          <AddIcon />
      </IconButton>
      </Grid>
    </Grid>
    <Grid 
    container 
    spacing={3}
    direction="row"
    justify="flex-start"
    alignItems="baseline">
    {structures&&structures.length>0&&getRootStructures().map((struct, key)=>{
      return(
      <Grid key={key} item style={{minWidth: 360 }}>
      <List style={{ width: '100%', border:"solid 1px #000000"}}>
        <ListItem>
            <ListItemText id={'id'} primary={
              <InputBase
                defaultValue={struct.name}
                inputProps={{ 
                  'aria-label': 'naked',
                  'data-structid': struct._id
                }}
                onBlur={(e)=>handleStructNameBlur(e)}
                onFocus={(e)=>handleStructNameFocus(e)}
              />
              } />
            <ListItemSecondaryAction>
              <IconButton
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={(e)=>handleAddChildClick(e, struct)}>
                <AddIcon />
              </IconButton>
              <IconButton
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={(e)=>handleRemoveStructClick(e, struct)}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        {getChildren(struct).map((child, key)=>{
          return(
            <React.Fragment key={key}>
            <ListItem style={{ paddingLeft: 40}}>
              <ListItemText id={'id'} primary={
                 <InputBase
                 defaultValue={child.name}
                 inputProps={{ 
                   'aria-label': 'naked',
                   'data-structid': child._id
                 }}
                 onBlur={(e)=>handleStructNameBlur(e)}
                 onFocus={(e)=>handleStructNameFocus(e)}
               />
              } />
              <ListItemSecondaryAction>
                <IconButton
                variant="contained"
                disabled={loading}
                color="primary"
                onClick={(e)=>handleAddChildClick(e, child)}>
                  <AddIcon />
                </IconButton>
                <IconButton
                variant="contained"
                disabled={loading}
                color="primary"
                onClick={(e)=>handleRemoveStructClick(e, child)}>
                  <CloseIcon />
              </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {getChildren(child).map((subchild, key)=>{
              return(
                <ListItem key={key} style={{ paddingLeft: 60}}>
                  <ListItemText id={'id'} primary={
                    <InputBase
                    defaultValue={subchild.name}
                    inputProps={{ 
                      'aria-label': 'naked',
                      'data-structid': subchild._id
                    }}
                    onBlur={(e)=>handleStructNameBlur(e)}
                    onFocus={(e)=>handleStructNameFocus(e)}
                  />
                  } />
                  
                  <ListItemSecondaryAction>
                  <IconButton
                  variant="contained"
                  disabled={loading}
                  color="primary"
                  onClick={(e)=>handleRemoveStructClick(e, subchild)}>
                    <CloseIcon />
                </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                )
            })}
            </React.Fragment>
            )
            
        })}
        </List>
        </Grid>
      )
    })}
    </Grid>
    
    </React.Fragment>

  )
}

export default function SchoolView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [schoolId, setSchoolId] = React.useState(props._schoolid);
  const [selectedOption, setSelectedOption] = React.useState(1);
  const propsUser = props.user;
  const [school, setSchool] = React.useState({});

  /* const [profileImageSrc, setProfileImageSrc] = React.useState(); */

  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;

  const classes = useStyles();
   
  React.useEffect(()=>{
      setTimeout(()=>{
        if(error !== ''){
          setError('');
        }
      }, 3000);
  },[error]);
  
  React.useEffect(()=>{

    isMounted.current = true;
    
    const loadSchool = () => {
      setLoading(true);
      ApiFunctions.getSchool(propsUser, schoolId).then(res=>{
        console.log(res);
        setSchool(res);
      }).catch(e=>{
          setError("Cannot get school information. Please try again later.");
      }).finally(()=>{
        setLoading(false);
      });
    }

    //load existing school
    if(schoolId)
    loadSchool();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [schoolId, propsUpdateUser, propsUser]);

  const handleSelectedOption = (event, option) =>{
    setSelectedOption(option);
  }

  const saveSchoolInfo = (schoolToSave) =>{
    console.log(schoolToSave);
    setButtonLoading(true);
    ApiFunctions.saveSchool(propsUser, schoolToSave).then(res=>{
      console.log(res);
      setSchool(schoolToSave);
    }).catch(e=>{
      setError("Something went wrong. Try again later.");
    }).finally(()=>{
      setButtonLoading(false);
    });
  }

  return (
    
    <Container maxWidth="xl" className={classes.container}>
    <Box>
    {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
    </Box>
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
      {school &&
      <h2 style={{padding:0, margin:0}}> {school.name?school.name : <Skeleton style={{width:300,height:30}} animation="wave" />}</h2>
      }
      
      </Box>
    </Box>

    <Paper className={classes.paper}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={3} xl={2}>
        <Paper className={classes.paper}>
          <SchoolOptionsList selectedOption={selectedOption} handleSelectedOption={handleSelectedOption} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8} lg={9} xl={10}>
        <Paper className={classes.paper}>
        {selectedOption===1 &&school &&
          <SchoolInfoView 
            school={school}
            propsUser={propsUser}
            setSchool={setSchool}
            saveSchoolInfo={saveSchoolInfo}
            setError={setError}
            buttonLoading={buttonLoading}
            />
        }
        
        {selectedOption===2&&
          <SchoolStructuresView 
            school={school}
            propsUser={propsUser}
            setError={setError}
          />
        }
        </Paper>
      </Grid>
      </Grid>
    
    </Paper>
    
    </Container>
  );
}