import axios from 'axios'
/* import data from './data'; */

const apiuri = "https://cloudibs.com:8083";

export const ApiFunctions = {
    getApiUri: ()=>{
        return apiuri;
    },  

    /**User Functions */
    signInUser: (username, password) =>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/authenticate', {username: username, password:password})
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            });
        });
    },
    signUpUser: (realname,username,password,passwordagain) =>{
        return new Promise ((resolve, reject) =>{
          axios.post(apiuri + '/adminsignup', 
          { realname: realname,
            username: username, 
            password: password, 
            passwordagain: passwordagain,
          }).then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            })
        })
    },
    sendResetPasswordRequest: (username) =>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri+"/forgotPassword", {username: username})
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }) 
        })
    },
    resetPassword: (username, resetPassToken) =>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri+"/forgotPassword/"+resetPassToken, 
            {username: username, token: resetPassToken})
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }) 
        })
    },
    activateAccount: (user, activationCode) =>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri+"/api/verifyAccount", 
            { 
                activationkey: activationCode, 
            },{
                headers: {
                  'x-access-token': user.token
                }
              })
            .then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },


    /**User Management */
    listUsers: (user)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri+"/api/users", {},
            {
                headers: {
                  'x-access-token': user.token
                }
              })
            .then((response)=>{
                console.log('list users response', response);
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    listMoreUsers: (user, searchPhrase, lastID)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri+"/api/more/users",
            {   searchPhrase: searchPhrase,
                lastID: lastID
            }, 
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    countAllUsers: (user)=>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri+"/api/count/users",
            {
                headers: {
                  'x-access-token': user.token
                }
              })
            .then((response)=>{
                console.log('count users response', response);
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    getUser: (user, _userid)=>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri+"/api/users/"+_userid,
            {
                headers: {
                  'x-access-token': user.token
                }
              })
            .then((response)=>{
                console.log('user response', response);
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    saveUser: (thisUser, thatUser)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/users/'+thatUser._id, {user:thatUser},
            {headers: {
                'x-access-token': thisUser.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },

    /**Schools */
    listSchools: (user)=>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri+"/api/schools",
            {
                headers: {
                  'x-access-token': user.token
                }
            })
            .then((response)=>{
                console.log('list schools response', response);
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    saveSchool: (user, schoolToSave)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/schools', {school:schoolToSave},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getSchool: (user, schoolId)=>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/schools/'+schoolId,
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getSchoolStructures: (user, schoolId)=>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/schools/'+schoolId+'/structures',
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    saveSchoolStructure: (user, schoolId, structToSave)=>{
        console.log(structToSave);
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/schools/'+schoolId+'/structures', {structure:structToSave},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    removeSchoolStructure: (user, schoolId, structIdsToRemove)=>{
        return new Promise ((resolve, reject) =>{
            axios.delete(apiuri + '/api/schools/'+schoolId+'/structures',
            {   data: {structIdsToRemove:structIdsToRemove},
                headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },

    /**School Levels */
    getAllStructures: (user) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/structures',
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getAllLevels: (user) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/levels',
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    saveSchoolLevel: (user, levelToSave)=>{
        console.log(levelToSave);
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/levels', {level:levelToSave},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getLevel: (user, levelId) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/levels/'+levelId,
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getLevelIntervals: (user, levelId) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/levels/'+levelId+"/intervals",
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    saveLevelInterval:(user, levelId, intervalToSave)=>{
        console.log(intervalToSave);
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/levels/'+levelId+"/intervals", {interval:intervalToSave},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    removeLevelInterval:(user, levelId, intervalToRemove)=>{
        //console.log(intervalToSave);
        return new Promise ((resolve, reject) =>{
            axios.delete(apiuri + '/api/levels/'+levelId+"/intervals",
            {   data: {interval:intervalToRemove},
                headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getTermsInSchool: (user, _schoolid) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/schools/'+_schoolid+'/terms',
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                    resolve(response.data);
                }).catch((error)=>{
                    reject(error);
                })
            })
    },
    getStudentGroups: (user, levelId) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/levels/'+levelId+"/groups",
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    saveStudentGroup:(user, levelId, groupToSave)=>{
        console.log(groupToSave);
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/levels/'+levelId+"/groups", {group:groupToSave},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getLevelSubjects: (user, levelId) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/levels/'+levelId+"/subjects",
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    saveLevelSubject:(user, levelId, subjectToSave)=>{
        console.log(subjectToSave);
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/levels/'+levelId+"/subjects", {subject:subjectToSave},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    addSubjectInstructor: (user, levelId, subjectId, instructorId)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/levels/'+levelId+"/subjects/"+subjectId+ "/instructors",{
                _instructorid: instructorId
            },
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          });
    },
    removeSubjectInstructor: (user, levelId, subjectId, instructorId)=>{
        return new Promise ((resolve, reject) =>{
            axios.delete(apiuri + '/api/levels/'+levelId+"/subjects/"+subjectId+ "/instructors",
            {   data: {_instructorid:instructorId},
                headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getLevelEnrollments: (user, levelId, year) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/levels/'+levelId+"/enrollments/"+year,
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    enrollStudentInLevel:(user, levelId, termId, studentId)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/levels/'+levelId+"/enrollments/"+termId, {_studentid:studentId},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    changeEnrollmentGroup: (user, levelId, termId, enrollmentId, groupId)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/levels/'+levelId+"/enrollments/"+termId+"/group", {_enrollmentid:enrollmentId, _groupid: groupId},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    unenrollStudentInLevel: (user, levelId, termId, enrollmentId)=>{
        return new Promise ((resolve, reject) =>{
            axios.delete(apiuri + '/api/levels/'+levelId+"/enrollments/"+termId,
            {   data: {_enrollmentid:enrollmentId},
                headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getLevelSchedule: (user, levelId, termId) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/levels/'+levelId+"/schedule/"+termId,
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    },
    getInstructorSchedules: (user, levelId, termId) =>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri + '/api/levels/'+levelId+"/instructor_schedules/"+termId,
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          });
    },
    saveLevelSchedule:(user, levelId, sectionPeriods, newPeriods, deletedPeriods)=>{

        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/levels/'+levelId+"/schedule", 
            {sectionPeriods:sectionPeriods, newPeriods:newPeriods, deletedPeriods:deletedPeriods},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                resolve(response.data);
              }).catch((error)=>{
                reject(error);
              })
          })
    },
    saveInstructorAssignments:(user, levelId, termId, instructorAssignments)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/levels/'+levelId+"/schedule/"+termId+"/assignments", 
            {instructorAssignments:instructorAssignments},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                console.log(response);
                resolve(response.data);
              }).catch((error)=>{
                reject(error);
              })
          })
    },

    /**Admission Applications */
    listApplications: (user)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri+"/api/applications", {},
            {
                headers: {
                  'x-access-token': user.token
                }
              })
            .then((response)=>{
                console.log('list applications response', response);
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    listMoreApplications: (user, searchPhrase, lastID, _schoolid)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri+"/api/more/applications",
            {   searchPhrase: searchPhrase,
                lastID: lastID,
                _schoolid: _schoolid
            }, 
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    countAllApplications: (user)=>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri+"/api/count/applications",
            {
                headers: {
                  'x-access-token': user.token
                }
              })
            .then((response)=>{
                console.log('count applications response', response);
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    getApplication: (user, _applicationid)=>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri+"/api/applications/"+_applicationid,
            {
                headers: {
                  'x-access-token': user.token
                }
              })
            .then((response)=>{
                console.log('application response', response);
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    getApplicationAttachmentsInfo: (user, _applicationid)=>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri+"/api/attachments/applications/"+_applicationid,
            {
                headers: {
                  'x-access-token': user.token
                }
              })
            .then((response)=>{
                console.log('application attachments response', response);
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    getApplicationCommands: (user)=>{
        return new Promise ((resolve, reject) =>{
            axios.get(apiuri+"/api/commands/applications",
            {
                headers: {
                  'x-access-token': user.token
                }
              })
            .then((response)=>{
                console.log('application commands response', response);
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            }); 
        });
    },
    executeApplicationCommand: (user, _applicationid, commandhuman)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/commands/applications/'+_applicationid
            , {commandhuman:commandhuman},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  console.log(error);
                  reject(error);
              })
        });
    },
    admitStudent: (user, _applicationid)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/admissions/applications/'+_applicationid
            , {},
            {headers: {
                'x-access-token': user.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  console.log(error);
                  reject(error);
              })
        });
    }
    /* saveUser: (thisUser, thatUser)=>{
        return new Promise ((resolve, reject) =>{
            axios.post(apiuri + '/api/users/'+thatUser._id, {user:thatUser},
            {headers: {
                'x-access-token': thisUser.token
            }}).then((response)=>{
                  resolve(response.data);
              }).catch((error)=>{
                  reject(error);
              })
          })
    }, */
}