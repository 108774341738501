import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';

import Popover from '@material-ui/core/Popover';
import Alert from '@material-ui/lab/Alert';

import { Router } from "@reach/router";

import {MainListItems} from '../../common/LeftNavView';
import SectionsView from "../SectionsView";
import SectionView from "../SectionView";
import ManageSectionsView from "../ManageSectionsView";
import ManageSectionView from "../ManageSectionView";
import MyScheduleView from "../MyScheduleView";
import ProfileView from "../ProfileView";
import CalendarView from "../CalendarView";
import SubjectsView from "../SubjectsView";
import SubjectView from "../SubjectView";

import UsersList from '../Users/List';
import UserManage from '../Users/Manage';

import SchoolsList from '../Schools/List';
import SchoolManage from '../Schools/Manage';

import LevelsList from '../Levels/List';
import LevelManage from '../Levels/Manage';

import ApplicationsList from '../Applications/List';
import ApplicationManage from '../Applications/Manage';

import axios from 'axios';
import edusim from '../../config/edusim';

import { RRule, RRuleSet, rrulestr } from 'rrule';
import Dashboard from './Dashboard';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  notificationsContainer: {
    minWidth: '500px',
    maxWidth: "500px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  notificationsRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleNotifcationsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const notificationsOpen = Boolean(anchorEl);
  const notificationsId = notificationsOpen ? 'simple-popover' : undefined;

  const [error, setError] = React.useState('');
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;

  //Base Calendar Settings
  //Session Matching Calendar
  let rule = RRule.fromString("FREQ=WEEKLY;dtstart=20210905;WKST=SU;UNTIL=20211223T205959Z;BYDAY=MO,SU,TH,TU,WE");
  let datesArray = rule.all();
  let days = [0,1,2,3,4,5];
  let periodTimes = [{title:"Lineup", start:"07:00", end: "07:15"},
                  {title:"Period 1", start:"07:15", end: "08:00"},
                  {title:"Period 2", start:"08:00", end: "08:45"},
                  {title:"Period 3", start:"08:45", end: "09:30"},
                  {title:"Break 1", start:"09:30", end: "10:15"},
                  {title:"Period 4", start:"10:15", end: "11:00"},
                  {title:"Period 5", start:"11:00", end: "11:45"},
                  {title:"Break 2", start:"11:45", end: "12:30"},
                  {title:"Period 6", start:"12:30", end: "13:15"},
                  {title:"Period 7", start:"13:15", end: "14:00"},
                  ];

  const calendarSettings = {
    datesArray: datesArray,
    days: days,
    periodTimes: periodTimes
  }

  React.useEffect(()=>{

    isMounted.current = true;
    axios.get(edusim.api_base_uri + '/api/admin', {
      headers: {
        'x-access-token': propsUser.token
      }
    }).then((res)=>{
      console.log(res);
    });

    return () => {
        // executed when unmount
        isMounted.current = false;
    }

  },[propsUpdateUser, propsUser]);

  React.useEffect(()=>{
    setTimeout(()=>{
      if(isMounted.current){
        if(error !== ''){
          setError('');
        }
      }
    }, 3000);
  },[error]);


  return (
    <div className={classes.root}>
      <CssBaseline />
      
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Cacti Software - Admin App
          </Typography>
          <IconButton color="inherit" onClick={handleNotifcationsClick}>
            
            <Badge badgeContent={null} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popover
            id={notificationsId}
            open={notificationsOpen}
            anchorEl={anchorEl}
            onClose={handleNotificationsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <NotificationsPane />
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems updateUserInformation={props.updateUserInformation} />
        </List>
        {/* <Divider />
        <List>{secondaryListItems}</List> */}
      </Drawer>

      {/*  Main View */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Router primary={false}>
          <Dashboard classes={classes} path="/" updateUserInformation={props.updateUserInformation} user={props.user} error={error} setError={setError}  />
          <SectionsView path="/sections" updateUserInformation={props.updateUserInformation} user={props.user} />
          <SectionView calendarSettings={calendarSettings} path="/sections/:_termid/:_sectionid" updateUserInformation={props.updateUserInformation} user={props.user} />
          <ManageSectionsView path="/manage/sections" updateUserInformation={props.updateUserInformation} user={props.user} />
          <ManageSectionView path="/manage/sections/:_termid/:_sectionid" updateUserInformation={props.updateUserInformation} user={props.user} />
          <CalendarView calendarSettings={calendarSettings} path="/calendar" />

          <SectionsView scheduleView={true} path="/schedule" updateUserInformation={props.updateUserInformation} user={props.user} />

          <ProfileView path="/profile" updateUserInformation={props.updateUserInformation} user={props.user} />

          <SubjectsView path="/subjects" updateUserInformation={props.updateUserInformation} user={props.user} />
          <SubjectView path="/subjects/:_subjectid" updateUserInformation={props.updateUserInformation} user={props.user} />

          <UsersList path="/users" updateUserInformation={props.updateUserInformation} user={props.user} />
          <UserManage path="/users/:_userid" updateUserInformation={props.updateUserInformation} user={props.user} />

          <SchoolsList path="/schools" updateUserInformation={props.updateUserInformation} user={props.user} />
          <SchoolManage path="/schools/:_schoolid" updateUserInformation={props.updateUserInformation} user={props.user} />

          <LevelsList path="/levels" updateUserInformation={props.updateUserInformation} user={props.user} />
          <LevelManage path="/levels/:_levelid" updateUserInformation={props.updateUserInformation} user={props.user} />

          <ApplicationsList path="/applications" updateUserInformation={props.updateUserInformation} user={props.user} />
          <ApplicationManage path="/applications/:_applicationid" updateUserInformation={props.updateUserInformation} user={props.user} />

        </Router>
      </main>
    </div>
  );
}

function NotificationsPane(props){

  const classes = useStyles();

  return(
    <Container className={classes.notificationsContainer}>
      <List className={classes.notificationsRoot}>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Beta Release 1"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  Notifications are disabled at the moment
                </Typography>
                {" — You will start receiving notifications here."}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>

    </Container>
  );

}
