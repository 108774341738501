import React from 'react';

import {
    Grid,
    IconButton, 
    Select, 
    MenuItem,
    Typography,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Table,
    Tooltip,
    makeStyles,
    TextField,
    Button
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { 
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    Edit as EditIcon,     
} from '@material-ui/icons';

import FloatingActionButton from './FAB';

import AssignmentGradeEntry from '../views/AssignmentGradeEntry';
import AssignmentView from '../views/AssignmentView';
import axios from 'axios';
import edusim from '../config/edusim';

import SubjectObjectivesView from './SubjectObjectivesView';

const SubjectProgReportView = (props) => {
    const subject = props.subject;
    
    //on edit
    const propsReport = props.selectedReport;
    
    //on add
    const selectedGi = props.selectedGi;
    const selectedYear = props.selectedYear;
    const selectedTerm = props.selectedTerm;

    const [progressReport, setProgressReport] = React.useState(propsReport);

    const propsUser = props.propsUser;
    const propsUpdateUser = props.propsUpdateUser;

    //error handling states
    const [error, setError] = React.useState('');
    const [errors, setErrors] = React.useState({});
    const [errorMessages, setErrorMessages] = React.useState({});

    const [selectedObjectives, setSelectedObjectives] = React.useState({});

    const handleCloseClick = (e)=>{
        props.setNewReportVisible(false);
    }

    return (
        <React.Fragment>       
            {(error !== '') &&
            <Alert style={{marginBottom: 10}} severity='error'>
                {error}
            </Alert>
            }
            <Typography component="h2" variant="h6" color="primary" gutterBottom>{propsReport?'Edit ':'Add '} Progress Report</Typography>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        defaultValue={propsReport?propsReport.term.name : (selectedTerm.name + ' ' + selectedYear)}
                        label="Term"
                        disabled={true}
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        defaultValue={propsReport?propsReport.grading_interval.name : (selectedGi.name + '(' + selectedGi.short_name + ')')}
                        label="Grading Interval"
                        disabled={true}
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
            </Grid>
            
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        defaultValue={propsReport?propsReport.name : ''}
                        label="Report Name"
                        variant="outlined" 
                        fullWidth
                        autoFocus>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        defaultValue={propsReport?propsReport.deadline : ''}
                        label="Report Deadline"
                        variant="outlined" 
                        fullWidth>
                    </TextField>
                </Grid>
            </Grid>

            <Typography component="h2" variant="h6" color="default">Select Objectives</Typography>

            <SubjectObjectivesView objectives={props.objectives} setSelectedObjectives={setSelectedObjectives} />
            

            <Grid container 
                direction="row"
                justify="flex-end"
                alignItems="flex-end" spacing={3}>
                <Grid item>
                    <Button
                        onClick={(e) => handleCloseClick(e)}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                       /*  onClick={(e) => editSessionVisible ? handleSaveSession(e):handleAddSession(e)} */>
                        {propsReport ? "Save" : "Add"} Report
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};
export default SubjectProgReportView;