import React, { useState } from 'react';
import {Container,Grid,Paper,Typography,Box} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Copyright from '../../common/Copyright';

import { VictoryChart, VictoryGroup, VictoryArea, VictoryTheme } from 'victory';

const gaussian = require('gaussian');

export default function Dashboard(props){
    const classes = props.classes;
    const [normalizedData, setNormalizedData] = useState([]);
    const findMean = (arr = []) => {
      let itemSum = 0;
      arr.forEach((item)=>{
        itemSum += item;
      });
      return (itemSum/(arr.length));
    };
    
    const findVariance = (arr = []) => {
      if(!arr.length){
          return 0;
      };
      const sum = arr.reduce((acc, val) => acc + val);
      const { length: num } = arr;
      const median = sum / num;
      let variance = 0;
      arr.forEach(num => {
          variance += ((num - median) * (num - median));
      });
      variance /= num;
      return variance;
    };
    
    const arr = [68, 68, 75, 75, 78, 79, 81, 81, 85, 90, 92, 95, 96, 97, 97];
    const dMean = findMean(arr);
    const dStdv = Math.sqrt(findVariance(arr));
    const threeSigma = dMean+(3*dStdv);
    const mthreeSigma  = dMean-(3*dStdv);

    React.useEffect(()=>{
      let beginNumber = Math.floor(mthreeSigma-1.5);
      let endNumber = Math.ceil(threeSigma+1.5)
      console.log(dMean,dStdv);
      let normalized = [];
      var distribution = gaussian(dMean,findVariance(arr));
      for(let s=beginNumber; s<=endNumber;s++){
        normalized.push({
          x: s,
          y: distribution.pdf(s)
        });
      }
      setNormalizedData(normalized);
      
      /* for(let s=beginNumber; s<=endNumber;s++){
        console.log(distribution.pdf(s));
      } */
    },[])

    return(
      !props.user.active||!props.user.mobile?
        <Grid item xs={12}>
                <Alert style={{marginBottom: 10}} severity='error'>
                    Both your email and mobile must be verified to use this service
                </Alert>
              </Grid>
      :
      <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={3}>
              {(props.error !== '') &&
              <Grid item xs={12}>
                <Alert style={{marginBottom: 10}} severity='error'>
                    {props.error}
                </Alert>
              </Grid>
              }
              {/* Important Information */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper className={classes.graphPaper}>
              <VictoryChart>
                <VictoryGroup
                  style={{
                    data: { strokeWidth: 3, fillOpacity: 0.4 }
                  }}
                >
                  <VictoryArea
                    style={{
                      data: { fill: "cyan", stroke: "cyan" }
                    }}
                    data={normalizedData}
                    animate={{ duration: 1000, easing: "circle" }}
                  />
                  {/* <VictoryArea
                    style={{
                      data: { fill: "magenta", stroke: "magenta" }
                    }}
                    data={[
                      { x: 1, y: 3 },
                      { x: 2, y: 2 },
                      { x: 3, y: 6 },
                      { x: 4, y: 2 },
                      { x: 5, y: 6 }
                    ]}
                  /> */}
                </VictoryGroup>
              </VictoryChart>
              </Paper>
            </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Other Really Important Information
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
    );
  }