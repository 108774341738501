import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';


import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';

import Box from '@material-ui/core/Box';

import data from '../config/data';

import SubjectCatTempsView from './SubjectCatTempsView';
import SubjectSectionsView from './SubjectSectionsView';
import SubjectInstructorsView from './SubjectInstructorsView';
import SubjectObjectivesView from './SubjectObjectivesView';
import SubjectProgressView from './SubjectProgressView';

import axios from 'axios';
import edusim from '../config/edusim';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  control: {
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  studentAvatar:{
    backgroundColor: 'transparent'
  },
  wrapper: {
    position: 'relative',
  },
  inButtonProgress: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
  },
  uploadButton: {
    width:'100%'
  },
  dropZoneClass:{
    maxHeight: '200px'
  },
  infoHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
    /* flexBasis: '33.33%',
    flexShrink: 0, */

  },
  infoHeadingBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 0,
  },
  infoBox: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '75%',
    flexShrink: 0,
  },
  infoSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  enrollmentsListRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  accordianHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accordianSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const SubjectOptionsList = ({selectedOption, handleSelectedOption}) =>{
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>Subject Options</Typography>
      <List component="nav" className={classes.enrollmentsListRoot}>

        
        <ListItem
          button
          selected={selectedOption === 1}
          onClick={(event) => handleSelectedOption(event, 1)}
        >
          <ListItemText 
          primary={'Category Templates'}
          secondary={'Manage Templates'} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 2}
          onClick={(event) => handleSelectedOption(event, 2)}
        >
          <ListItemText 
          primary={'Sections'}
          secondary={'Term Sections'} />
        </ListItem>
        
        <ListItem
          button
          selected={selectedOption === 3}
          onClick={(event) => handleSelectedOption(event, 3)}
        >
          <ListItemText 
          primary={'Instructors'}
          secondary={'Instructors and Managers'} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 4}
          onClick={(event) => handleSelectedOption(event, 4)}
        >
          <ListItemText 
          primary={'Objectives'}
          secondary={'Objectives and Compentency'} />
        </ListItem>

        <ListItem
          button
          selected={selectedOption === 5}
          onClick={(event) => handleSelectedOption(event, 5)}
        >
          <ListItemText 
          primary={'Student Progress'}
          secondary={'Progress Reports'} />
        </ListItem>
        
    </List>
    </React.Fragment>
  )
}

/* const SubjectObjectivesView = (props) => {
  const objectives = props.objectives;
  const [selectedObject, setSelectedObject] = React.useState({});

  React.useEffect(()=>{
    let objectivesObject = {};
    objectives.map((objective, key)=>{
    objectivesObject[objective.domain] = {selected:false};
    objective.clusters&&objective.clusters.map((cluster, key)=>{
      objectivesObject[objective.domain][cluster.standard] = {selected:false}
      cluster.children&&cluster.children.map((standard, key)=>{
        objectivesObject[objective.domain][cluster.standard][standard.standard] = {selected: false};
        standard.children&&standard.children.map((substandard, key)=>{
          objectivesObject[objective.domain][cluster.standard][standard.standard][substandard.standard] = {selected: false};
        });
      });
    }); 
  });
  setSelectedObject(objectivesObject);

  }, [objectives]);
  

  function selectObjective(domain, cluster, standard, substandard) {
    let tempSelectedObject = {...selectedObject};
    if(!substandard)
      tempSelectedObject[domain][cluster][standard].selected = true;
    else
      tempSelectedObject[domain][cluster][standard][substandard].selected = true;

    setSelectedObject(tempSelectedObject);
    console.log(selectedObject);
  }
  function deselectObjective(objective) {
    
  }
  return (

    <Container maxWidth="xl">
      {objectives&&objectives.map((objective, key)=>(
        <Box key={key}>
        <Box>
          <b>{objective.domain}</b>
        </Box>
        <Box>
          {objective.clusters&&objective.clusters.map((cluster, key)=>(
             <Box key={key}>
             <Box>{cluster.standard}</Box>
              {cluster.children&&cluster.children.map((st, key)=>(
                <Box key={key}>
                  <Box style={{paddingLeft:15}}>

                    <Button onClick={()=>selectObjective(objective.domain, cluster.standard, st.standard)}>S</Button> {st.standard}

                    {selectedObject[objective.domain]&&selectedObject[objective.domain][cluster.standard]&&selectedObject[objective.domain][cluster.standard][st.standard]&&selectedObject[objective.domain][cluster.standard][st.standard].selected?'SEL':'USEL'}
                  </Box>
                  {st.children&&st.children.map((child, key)=>(
                    <Box key={key} style={{paddingLeft:30}}>
                      <Button onClick={()=>selectObjective(objective.domain, cluster.standard, st.standard, child.standard)}>S</Button> {st.standard}
                      {child.standard}
                    </Box>
                  ))}
                </Box>
              ))}
             </Box>
          ))}
        </Box>
        </Box>
        
      ))}      
    </Container>

  )



} */

export default function SubjectView(props) {
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [subjectId, setSubjectId] = React.useState(props._subjectid);
  const [selectedOption, setSelectedOption] = React.useState(1);
  const [activeTerms, setActiveTerms] = React.useState([]);
  const [yearsObject, setYearsObject] = React.useState({});
  
  const propsUser = props.user;
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;

  const propsTermId = parseInt(props._termid); 
 
  const classes = useStyles();

  const [subject, setSubject] = React.useState({});
  const [categoryTemplates, setCategoryTemplates] = React.useState([]);
  const [sections, setSections] = React.useState([]);
  

  const [objectivesVisible, setObjectivesVisible] = React.useState(false);

  const sampleObjectives = [{
    domain: "Counting and Cardinality",
    clusters: [
      {standard: "Know number names and the count sequence.",
       children:[
        {standard: "Count to 100 by ones and by tens.", children: []},
        {standard: "Count forward beginning from a given number within the known sequence (instead of having to begin at 1).", children:[]},
        {standard: "Write numbers from 0 to 20. Represent a number of objects with a written numeral 0-20 (with 0 representing a count of no objects).", children: []}
      ]},
      {standard: "Count to tell the number of objects.",
       children:[
        {standard: "Understand the relationship between numbers and quantities; connect counting to cardinality.", 
         children: [
          {standard: "When counting objects, say the number names in the standard order, pairing each object with one and only one number name and each number name with one and only one object."},
          {standard: "Understand that the last number name said tells the number of objects counted. The number of objects is the same regardless of their arrangement or the order in which they were counted."},
          {standard: "Understand that each successive number name refers to a quantity that is one larger."},
        ]},
        {standard: "Count to answer “how many?” questions about as many as 20 things arranged in a line, a rectangular array, or a circle, or as many as 10 things in a scattered configuration; given a number from 1–20, count out that many objects.", children:[]},
      ]},
      {standard: "Compare numbers.",
       children:[
        {standard: "Identify whether the number of objects in one group is greater than, less than, or equal to the number of objects in another group, e.g., by using matching and counting strategies.", children: []},
        {standard: "Compare two numbers between 1 and 10 presented as written numerals.", children:[]},
      ]},
    ]
  },
  {
    domain: "Operations and algebraic thinking",
    clusters: [
      {standard: "Understand addition as putting together and adding to, and under- stand subtraction as taking apart and taking from.",
       children:[
        {standard: "Represent addition and subtraction with objects, fingers, mental images, drawings2, sounds (e.g., claps), acting out situations, verbal explanations, expressions, or equations.", children: []},
        {standard: "Solve addition and subtraction word problems, and add and subtract within 10, e.g., by using objects or drawings to represent the problem.", children:[]},
        {standard: "Decompose numbers less than or equal to 10 into pairs in more than one way, e.g., by using objects or drawings, and record each decomposition by a drawing or equation (e.g., 5 = 2 + 3 and 5 = 4 + 1).", children: []},
        {standard: "For any number from 1 to 9, find the number that makes 10 when added to the given number, e.g., by using objects or drawings, and record the answer with a drawing or equation.", children: []},
        {standard: "Fluently add and subtract within 5.", children: []}
      ]},
    ]
  },
  {
    domain: "Number and operations in Base ten",
    clusters: [
      {standard: "Work with numbers 11-19 to gain foundations for place value.",
       children:[
        {standard: "Compose and decompose numbers from 11 to 19 into ten ones and some further ones, e.g., by using objects or drawings, and record each composition or decomposition by a drawing or equation (e.g., 18 = 10 + 8); understand that these numbers are composed of ten ones and one, two, three, four, five, six, seven, eight, or nine ones.", children: []},
      ]},
    ]
  },
  {
    domain: "Measurement and data",
    clusters: [
      {standard: "Describe and compare measurable attributes.",
       children:[
        {standard: "Describe measurable attributes of objects, such as length or weight. Describe several measurable attributes of a single object.", children: []},
        {standard: "Directly compare two objects with a measurable attribute in common, to see which object has “more of”/“less of” the attribute, and describe the difference. For example, directly compare the heights of two children and describe one child as taller/shorter.", children: []},
      ]},
      {standard: "Classify objects and count the number of objects in each category.",
       children:[
        {standard: "Classify objects into given categories; count the numbers of objects in each category and sort the categories by count.", children: []},
      ]},
    ]
  },
  {
    domain: "Geometry",
    clusters: [
      {standard: "Identify and describe shapes (squares, circles, triangles, rectangles, hexagons, cubes, cones, cylinders, and spheres).",
       children:[
        {standard: "Describe objects in the environment using names of shapes, and describe the relative positions of these objects using terms such as above, below, beside, in front of, behind, and next to.", children: []},
        {standard: "Correctly name shapes regardless of their orientations or overall size.", children: []},
        {standard: "Identify shapes as two-dimensional (lying in a plane, “flat”) or three- dimensional (“solid”).", children: []},
      ]},
      {standard: "Analyze, compare, create, and compose shapes.",
       children:[
        {standard: "Analyze and compare two- and three-dimensional shapes, in different sizes and orientations, using informal language to describe their similarities, differences, parts (e.g., number of sides and vertices/“corners”) and other attributes (e.g., having sides of equal length).", children: []},
        {standard: "Model shapes in the world by building shapes from components (e.g., sticks and clay balls) and drawing shapes.", children: []},
        {standard: "Compose simple shapes to form larger shapes. For example, Can you join these two triangles with full sides touching to make a rectangle?", children: []},
      ]},
    ]
  },
]

  const [objectives, setObjectives] = React.useState(sampleObjectives);


  const updateSubjectAssignments = (assignment) => {
    let foundIndex = subject.assignments ? subject.assignments.findIndex(e => e._id === assignment._id) : -1;
    if (foundIndex >= 0) {
        let tempArr = [...subject.assignments];
        tempArr[foundIndex] = assignment;
        setSubject((prev)=>{
          return {
            ...prev,
            assignments: tempArr
          }
        });
    }
  }

  const updateSubjectSessions = (session) =>{
    let foundIndex = subject.sessions ? subject.sessions.findIndex(e => e._id === session._id) : -1;
    if (foundIndex >= 0) {
        let tempArr = [...subject.sessions];
        tempArr[foundIndex] = session;
        setSubject((prev)=>{
          return {
            ...prev,
            sessions: tempArr
          }
        });
    }
  }

  const updateSubjectPlans = (plan) =>{
    let foundIndex = subject.plans ? subject.plans.findIndex(p => p._id === plan._id) : -1;
    if (foundIndex >= 0) {
        let tempArr = [...subject.plans];
        tempArr[foundIndex] = plan;
        setSubject((prev)=>{
          return {
            ...prev,
            plans: tempArr
          }
        });
    }
  }

  const addSubjectAssignment = (assignment) => {
    let tempArr = [...subject.assignments];
    tempArr.push(assignment);
    setSubject((prev)=>{
      return {
        ...prev,
        assignments: tempArr
      }
    });
  }

  function getYearsObject(terms){
    let yearsObj = {};
    terms.map((term)=>{
      yearsObj[term.year]?yearsObj[term.year].push(term):yearsObj[term.year]=[term];
    });
    console.log(yearsObj);
    return yearsObj;
  }

  React.useEffect(()=>{
      setTimeout(()=>{
        if(error !== ''){
          setError('');
        }
      }, 3000);
  },[error]);

  React.useEffect(() => {
    isMounted.current = true;
    const refreshTermsLevels = () => {
      axios.get(edusim.api_base_uri+"/api/instructors/termslevels",{
        headers: {
          'x-access-token': propsUser.token
        }
        }).then(res => {
          console.log("terms levels res", res);
          setLoading(false);
          setActiveTerms(res.data.terms);
          setYearsObject(getYearsObject(res.data.terms));
          //setActiveLevels(res.data.levels);
          //setCurrentTerm(res.data.terms?res.data.terms.length>0?res.data.terms[0]:null:null);
        }).catch(e => {
          if(e.response){
              if(isMounted.current){
                  setLoading(false);
              }
              if(e.response.status === 403){
                  propsUpdateUser({});
              }
          }else{
              if(isMounted.current){
                  setLoading(false);
                  setError("Network connection might be lost, ")
              }                    
          }
        });
    }

    refreshTermsLevels();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }

  }, [propsUpdateUser, propsUser]);


  React.useEffect(()=>{

    isMounted.current = true;
    
    const loadSubject = () => {
      setLoading(true);
      axios.get(edusim.api_base_uri+"/api/instructors/managers/getSubject/"+subjectId,{
        headers: {
          'x-access-token': propsUser.token
        }
      }).then(res => {
        console.log(res);
        setSubject(res.data.subject);
        setCategoryTemplates(res.data.categoryTemplates);
        setSections(res.data.sections);
        //setObjectives(res.data.objectives);
        setLoading(false);
      }).catch(e => {
        if(e.response){
            if(isMounted.current){
                setLoading(false);
            }
            if(e.response.status === 403){
                propsUpdateUser({});
            }
        }else{
            if(isMounted.current){
                setLoading(false);
                setError("Network connection might be lost, ")
            }                    
        }
        //console.log(e);
      });
    };

    //load existing subject
    if(subjectId)
      loadSubject();

    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [propsTermId, subjectId, propsUpdateUser, propsUser]);

  const handleSelectedOption = (event, option) =>{
    setSelectedOption(option);
  }

  const toggleObjectives = () =>{
    setObjectivesVisible(!objectivesVisible)
  }

  return (
    
    <Container maxWidth="xxl" className={classes.container}>
    <Box>
    {(error !== '') &&
        <Alert style={{marginBottom: 10}} severity='error'>
            {error}
        </Alert>
      }
    </Box>
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
      {subjectId &&
      <h2 style={{padding:0, margin:0}}> {subject.name&&subject._levelid? (subject._levelid.name +" - " + subject.name) : <Skeleton animation="wave" />}</h2>
      }
      
      </Box>
    </Box>
    
    {objectivesVisible&&
    <Paper className={classes.paper}>
      <SubjectObjectivesView objectives={objectives} />
    </Paper>}
    

    <Paper className={classes.paper}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={3} xl={2}>
        <Paper className={classes.paper}>
        <SubjectOptionsList selectedOption={selectedOption} handleSelectedOption={handleSelectedOption} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8} lg={9} xl={10}>
        <Paper className={classes.paper}>
        {selectedOption===1 &&subject && categoryTemplates && <SubjectCatTempsView categoryTemplates={categoryTemplates} subject={subject} propsUser={propsUser} />
        }

        {selectedOption===2 &&subject && sections &&<SubjectSectionsView subject={subject} sections={sections} propsUser={propsUser} />
        }

        {selectedOption===3 &&subject && <SubjectInstructorsView subject={subject} propsUser={propsUser} />
        } 

        {selectedOption===4 &&subject && objectives && <SubjectObjectivesView subject={subject} objectives={objectives} propsUser={propsUser} />
        } 

        {selectedOption===5 &&subject && objectives && <SubjectProgressView yearsObject={yearsObject} subject={subject} objectives={objectives} propsUser={propsUser} />
        }
        </Paper>
      </Grid>
      </Grid>
    
    </Paper>
    
    </Container>
  );
}