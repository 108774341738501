export default {
    //api_base_uri: "http://localhost:4000",
    api_base_uri: "https://cloudibs.com:8083",
    application: "ParentApp Web",
    valid_instructor_domain: "cloudibs.com",
    version: "1.0",
    admission_min_age: 3,
    admission_max_age: 19,
    parent_min_age: 21,
    profile_status_colors: {
        'Verified': "#32CD32",
        'Unverified': "#DC143C"
    },
    admission_status_colors: {
        'New': '#4682B4',
        'Submitted': '#FFD700',
        'Opened For Review': '#FF00FF',
        'Reviewed': '#FFA500',
        'Application Accepted': '#32CD32',
        'Application Rejected': '#DC143C',
        'Returned for More Information': '#DC143C',
        'Application Archived': '#FFD700',
        'Admitted': '#32CD32',
        'AdmittedAndEnrolled': '#32CD32'
    },
    profile_status_colors: {
        'Verified': "#32CD32",
        'Unverified': "#DC143C"
    },
    invoices_status_colors: {
        'Voided': "#DC143C",
        'Paid': '#32CD32',
        'Pending': '#FF00FF',
    }
};